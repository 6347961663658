var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-exploration-0"},[_c('div',{staticClass:"mobile-flashlight-container"},[_c('div',{staticClass:"mobile-flashlight-title font-weight-medium"},[_c('span',[_vm._v(_vm._s(_vm.getTitle)),_c('br'),_vm._v(_vm._s(_vm.getSubTitle))])]),_c('div',{staticClass:"mobile-flashlight-enter-button font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.getEnterBtnTitle))]),_c('img',{attrs:{"src":require("../../../assets/images/icon-arrow-right-black.svg"),"alt":""}})])]),_c('img',{staticClass:"flashlight",style:({
          left: _vm.flashlightPointData.Left + 'px',
          top: _vm.flashlightPointData.Top + 'px',
          width: _vm.flashlightPointData.Width + 'px',
          height: _vm.flashlightPointData.Height + 'px'
        }),attrs:{"id":"flashlight","src":require("../../../assets/images/flashlight-mobile.svg"),"alt":"flashlight"},on:{"load":_vm.flashlightLoaded}}),_c('div',{staticStyle:{"position":"absolute","border":"1px solid white","width":"18vw","height":"12vh","pointer-events":"none","visibility":"hidden"},style:({
          left: (_vm.flashlightPointData.Left + (_vm.flashlightPointData.Width / 2) - 100) + 'px',
          top: (_vm.flashlightPointData.Top + (_vm.flashlightPointData.Height / 2) - 50) + 'px',
        })}),_c('div',{staticClass:"enter-button-control",on:{"click":_vm.goNext}})])
}
var staticRenderFns = []

export { render, staticRenderFns }