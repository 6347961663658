<template>
  <div class="exploration-3D">
<!--    <model-obj :src="`https://homing.ntsec.gov.tw/files/${selectedInteraction.ModelFileName}`" backgroundColor="#000000"></model-obj>-->
    <model-gltf
        :src="getModelUrl"
        :backgroundColor="backgroundColor"
        :rotation="modelRotation"
        @on-load="handleLoaded"
    ></model-gltf>
    <div v-if="showTip" class="model-control-tip" @click="showTip = false">
      <img class="img-icon-360" src="../../../assets/images/icon-360.svg" alt="360拖曳可旋轉">
      <div class="control-tip-span font-weight-light">
        <span>試著上下、左右旋轉模型，觀賞更多角度細節</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  ModelGltf,
  // ModelObj
} from 'vue-3d-model';

export default {
  name: 'Exploration3D',
  components: {
    ModelGltf,
    // ModelObj
  },
  computed: {
    getModelUrl() {
      if (this.selectedInteraction && this.selectedInteraction.ModelFileName) {
        const fileName = this.selectedInteraction.ModelFileName
        if (process.env.NODE_ENV === 'development') {
          // return '/F30_耳廓狐.gltf'
          return '/Y1-L.gltf'
        } else if (this.selectedInteraction) {
          return `https://homing.ntsec.gov.tw/files/${fileName.replace('.obj', '.gltf')}`
        } else {
          return ''
        }
      }
      return ''
    },
    getModelMTLUrl() {
      if (process.env.NODE_ENV === 'development') {
        return '/Y62-L.mtl'
      } else if (this.selectedInteraction) {
        return `https://homing.ntsec.gov.tw/files/${this.selectedInteraction.ModelFileName}`
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      interactionList: [],
      selectedInteraction: null,
      backgroundColor: '#A9A9A9',
      modelRotation: {
        x: 90,
        y: -80,
        z: 0
      },
      showTip: true
    }
  },
  mounted() {
    this.$store.commit('LoadingOpen')
    this.$store.dispatch('GetInteractionList').then(res => {
      if (res.success) {
        try {
          this.interactionList = res.interactions.filter(x => x.InteractionType === 0)
          const filterList = this.interactionList.filter(x => x.InteractionId === parseInt(this.$route.query.id))
          const model = filterList[0] ? filterList[0] : this.interactionList[0]
          console.log(model)
          if (model.ModelRotation) {
            const values = model.ModelRotation.split(',')
            this.modelRotation = {
              x: values[0],
              y: values[1],
              z: values[2]
            }
          }
          this.selectedInteraction = model
        } catch (e) {
          this.$store.commit('LoadingClose')
          alert(e.toString())
        }
      }
    })
  },
  methods: {
    handleLoaded() {
      this.$store.commit('LoadingClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.exploration-3D {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #20272D;
  overflow: hidden;
  .model-control-tip {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    .img-icon-360 {
      position: absolute;
      width: 150px;
      height: 150px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    .control-tip-span {
      position: absolute;
      width: 100vw;
      right: 0;
      bottom: 50px;
      left: 0;
      margin: auto;
      text-align: center;
      color: #BCD7CE;
    }
  }
}
</style>
