import router from "@/router";

export default {
  name: 'Welcome',
  components: {
  },
  computed: {
    language: function () {
      return this.$store.getters.languageKey
    }
  },
  data() {
    return {
      intercomList: [
        require('../assets/images/welcome_intercom1.svg'),
        require('../assets/images/welcome_intercom2.svg'),
        require('../assets/images/welcome_intercom3.svg'),
        require('../assets/images/welcome_intercom4.svg'),
      ],
      intercomIndex: 0,
      welcomePlay: undefined
    }
  },
  destroyed() {
    console.log('destroyed')
    if (this.welcomePlay) {
      this.welcomePlay.pause()
    }
  },
  methods: {
    playAudio() {
      console.log('@click="playAudio"')
      this.welcomePlay = new Audio('/welcome.mp3')
      this.welcomePlay.play()
    },
    goNext () {
      router.push({
        name: 'DesktopMainMenu'
      })
    },
    changeIntercom(index) {
      this.intercomIndex = ((this.intercomIndex + index) + this.intercomList.length) % this.intercomList.length
    },
    handleChangeLang() {
      if (this.language === 'ZH_TW') {
        this.$store.dispatch('SetupLanguage', 'EN')
      } else {
        this.$store.dispatch('SetupLanguage', 'ZH_TW')
      }
    }
  }
}
