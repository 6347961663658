/*eslint no-useless-constructor: "error"*/
'use strict';
// icon 檔案

export class SquidManager {
  constructor() {
    if (SquidManager._instance) {
      return SquidManager._instance
    }
    console.log("=============================== Init PrinterEditorManager ===============================")
    SquidManager._instance = this;
    this.images = []
    for (let i = 0; i < 150; i++) {
      this.images.push(require(`../assets/images/ar-cuttle/squid/Squid_${i.toString().padStart(5, '0')}.png`))
    }
  }
}
