<template>
  <div class="mobile-welcome-index" @click="playAudio">
    <img class="welcome-logo" src="../../assets/images/welcome_logo.svg" alt="LOGO">
    <div class="intercom-container">
      <img :src="intercomList[intercomIndex]" alt="對講機">
      <div class="up-control" @click.stop="changeIntercom(-1)"></div>
      <div class="down-control" @click.stop="changeIntercom(1)"></div>
      <div class="enter-control" @click.stop="goNext"></div>
    </div>
    <div class="lang-icon font-weight-bold" @click.stop="handleChangeLang" style="user-select: none">
      <span v-if="language === 'ZH_TW'">中</span>
      <span v-else-if="language === 'EN'">EN</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../router'
import WelcomeMixin from "@/mixins/welcome-mixin";

export default {
  name: 'Welcome',
  mixins: [WelcomeMixin],
  components: {
  },
  computed: {
    // language: function () {
    //   return this.$store.getters.languageKey
    // }
  },
  data() {
    return {
      // intercomList: [
      //   require('../../assets/images/welcome_intercom1.svg'),
      //   require('../../assets/images/welcome_intercom2.svg'),
      //   require('../../assets/images/welcome_intercom3.svg'),
      //   require('../../assets/images/welcome_intercom4.svg'),
      // ],
      // intercomIndex: 0,
      // welcomePlay: undefined
    }
  },
  destroyed() {
    // console.log('destroyed')
    // if (this.welcomePlay) {
    //   this.welcomePlay.pause()
    // }
  },
  methods: {
    // playAudio() {
    //   console.log('@click="playAudio"')
    //   this.welcomePlay = new Audio('/welcome.mp3')
    //   this.welcomePlay.play()
    // },
    goNext () {
      router.push({
        name: 'MobileMainMenu'
      })
    },
    // changeIntercom(index) {
    //   this.intercomIndex = ((this.intercomIndex + index) + this.intercomList.length) % this.intercomList.length
    // },
    // handleChangeLang() {
    //   if (this.language === 'ZH_TW') {
    //     this.$store.dispatch('SetupLanguage', 'EN')
    //   } else {
    //     this.$store.dispatch('SetupLanguage', 'ZH_TW')
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.mobile-welcome-index {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #07DB95;
  background-image: url("../../assets/images/bg.png");
  background-size: cover;
  overflow: scroll;
  flex-direction: column;
  .welcome-logo {
    position: relative;
    width: 120%;
    padding: 25% 0 10% 0;
    margin-left: -20%;
  }
  .intercom-container {
    position: relative;
    height: auto;
    margin: 0 2.5%;
    width: 95%;
    img {
      width: 100%;
    }
    .up-control {
      position: absolute;
      left: 12%;
      top: 62%;
      width: 27%;
      height: 30vw;
      margin: auto;
      cursor: pointer;
      //background-color: rgba(255,255,255,0.6);
    }
    .down-control {
      position: absolute;
      left: 39%;
      top: 62%;
      width: 25%;
      height: 30vw;
      margin: auto;
      cursor: pointer;
      //background-color: rgba(255,255,255,0.6);
    }
    .enter-control {
      position: absolute;
      left: 64%;
      top: 62%;
      width: 27%;
      height: 30vw;
      margin: auto;
      cursor: pointer;
      //background-color: rgba(255,255,255,0.6);
    }
  }
  .lang-icon {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: url("../../assets/images/icon-lang.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #07DB95;
    font-size: 1.5rem;
    line-height: 60px;
  }
}
</style>
