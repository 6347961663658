<template>
  <div class="mobile-way-home-detail">
    <div v-if="collection" class="detail-container">
      <el-row>
        <el-col :span="24">
          <div class="detail-mainTitle">
            <div class="detail-title">
              <span>{{ getTitle(collection) }}</span>
            </div>
            <div class="detail-professionalName">
              <span>{{ getProfessionalName(collection) }}</span>
            </div>
            <div class="detail-highlightContent">
              <span>{{ getHighlightContent(collection) }}</span>
            </div>
          </div>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <div class="detail-image-block" :class="{ 'detail-image-block-scale': scaleDetailImg}">-->
<!--            <img class="scale-img" src="../../../assets/images/icon-detail-image-scale.svg" @click="scaleDetailImg = !scaleDetailImg" alt>-->
<!--            <img class="detail-img" :src="showImage" alt>-->
<!--            <span>{{ showImageName }}</span>-->
<!--          </div>-->
<!--        </el-col>-->
      </el-row>
      <div class="detail-mainContent">
        <span v-html="getMainContent(collection).replaceAll('\n', '<br>')"></span>
      </div>
    </div>
    <div class="mobile-left-top-back-button" @click="goBack">
      <img src="../../../assets/images/icon-arrow-left-green.svg" alt>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'
import axios from "axios";
import WayHomeDetailMixin from "@/mixins/way-home-detail-mixin";

export default {
  name: 'WayHomeDetail',
  mixins: [WayHomeDetailMixin],
  components: {
  },
  props: {
    dataId: {
      type: Number
    }
  },
  computed: {
    getDetailImage() {
      if (this.collection && this.collection.CollectionDetails[0].CollectionDetailAudios.length > 0) {
        return `https://homing.ntsec.gov.tw/files/${this.collection.CollectionDetails[0].CollectionDetailAudios[0].ImagePath}`
      }
      return ''
    },
    getDetailImageIntro() {
      if (this.collection && this.collection.CollectionDetails[0].CollectionDetailAudios.length > 0) {
        return this.collection.CollectionDetails[0].CollectionDetailAudios[0].Introduction
      }
      return ''
    },
    showImage() {
      return (this.$store.getters.playingCollectionId === parseInt(this.collectionId) && this.$store.getters.showAudioImage && this.$store.getters.showAudioImage.ImagePath.trim().length > 0) ?
          ('https://homing.ntsec.gov.tw/files/' + this.$store.getters.showAudioImage.ImagePath) : this.getDetailImage
      // 'https://homing.ntsec.gov.tw/files/' + filter.ImagePath
    },
    showImageName() {
      return (this.$store.getters.showAudioImage) ?
          (this.$store.getters.showAudioImage.Introduction) : this.getDetailImageIntro
    }
  },
  watch: {
  },
  data() {
    return {
      collectionId: undefined,
      collection: undefined,
      scaleDetailImg: false
    }
  },
  mounted () {
    if (this.dataId !== null) {
      this.collectionId = this.dataId
    } else if (this.$route.params.id) {
      this.collectionId = this.$route.params.id
    }

    if (this.collectionId) {
      axios.get('https://homing.ntsec.gov.tw/api/Collection/Info/' + this.$route.params.id).then(res => {
        console.log(res)
        this.collection = res.data.Result
      })
    }
  },
  methods: {
    goBack() {
      if (this.dataId) {
        this.$emit('close')
      } else {
        router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-way-home-detail {
  position: relative;
  background-color: #CCDBCC;
  width: 100%;
  min-height: 100vh;
  padding: 100px 0;
  .detail-container {
    width: 80%;
    max-width: 786px;
    margin: auto;
    text-align: left;
    .detail-mainTitle {
      .detail-title {
        //font-family: "Noto Sans TC", sans-serif ;
        font-weight: 600;
        font-size: 1.5rem;
        color: #20272D;
        //width: 50%;
      }
      .detail-professionalName {
        font-weight: 400;
        font-size: 1.3rem;
        color: #20272D;
        margin-top: 10px;
        //width: 50%;
      }
      .detail-highlightContent {
        font-weight: 600;
        font-size: 1.4rem;
        color: #20272D;
        text-decoration: underline;
        text-decoration-color: #07DB95;
        text-underline-offset: 0.2em;
        margin-top: 30px;
        //width: 50%;
      }
    }
    .detail-image-block {
      position: absolute;
      right: 0;
      top: 0;
      background-color: white;
      width: calc(95vw * 0.4);
      height: calc(95vw * 0.35);
      display: flex;
      flex-direction: column;
      max-width: 375px;
      max-height: 300px;
      .detail-img {
        background: black;
        margin: 10px 10px 0 10px;
        width: calc(100% - 20px);
        height: calc(100% - 44px);
        object-fit: contain;
      }
      .scale-img {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      span {
        line-height: 30px;
        padding: 2px 10px;
        min-height: 30px;
      }
    }
    .detail-image-block-scale {
      position: fixed;
      //width: calc(80vw);
      //height: calc(80vh);
      //left: 10vw;
      //right: 10vw;
      //top: 10vh;
      //bottom: 10vh;
      animation-name: open-img-scale;
      animation-duration: 0.5s;
      max-width: unset;
      max-height: unset;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    @keyframes open-img-scale {
      0%{
        width: calc(95vw * 0.4);
        height: calc(95vw * 0.35);
        //left: 0;
        right: 0;
        top: 0;
        margin: auto;
        opacity: 0;
        //bottom: 0;
      }
      30% {
        opacity: 0.3;
      }
      100%{
        width: 80vw;
        height: 80vh;
        right: 10vw;
        top: 10vh;
        //bottom: 10vh;
        opacity: 1;
      }
    }
    .detail-mainContent {
      font-weight: 400;
      font-size: 1.3rem;
      color: #20272D;
      margin-top: 30px;
      width: 100%;
    }
  }
}
</style>
