<template>
  <div class="way-home-map" :style="{ backgroundImage: `url('https://homing.ntsec.gov.tw/files/${getMapUrl}')`}">
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'

export default {
  name: 'WayHomeMap',
  components: {},
  props: {
    dataUrl: {
      type: String
    }
  },
  computed: {
    getMapUrl() {
      if (this.dataUrl) {
        return this.dataUrl
      } else {
        return decodeURIComponent(this.$route.params.map)
      }
    }
  },
  data() {
    return {
      mapUrl: undefined
    }
  },
  mounted() {
    this.$store.commit('SetBackButtonColor', 'green')
  },
  methods: {
    goBack() {
      this.$store.commit('SetBackButtonColor', undefined)
      if (this.dataUrl) {
        this.$emit('close')
      } else {
        router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.way-home-map {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/images/way-home-0-bg.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #20272D;
  overflow: hidden;

  //img {
  //  position: absolute;
  //  left: -50%;
  //  right: -50%;
  //  top: -50%;
  //  bottom: -50%;
  //  width: 200%;
  //  height: 200%;
  //}
}
</style>
