<template>
  <div>
    <img :src="squid" alt style="width: 100%; height: 100%">
  </div>
</template>

<script>
import {SquidManager} from "@/components/squid-manager";

export default {
  name: "squid",
  data() {
    return {
      squid: require('../assets/images/ar-cuttle/squid/Squid_00000.png'),
      index: 0,
      squidManager: new SquidManager()
    }
  },
  mounted() {
    const _this = this
    _this.squid = this.squidManager.images[_this.index]
    setInterval(function () {
      _this.index++;
      _this.index = _this.index % 150
      _this.squid = _this.squidManager.images[_this.index]
    }, 20)
  }
}
</script>

<style scoped>

</style>
