import WayHomeSearchCollection from "@/views/desktop/WayHome/WayHomeSearchCollection";
import WayHomeMap from "@/views/desktop/WayHome/WayHomeMap";
import WayHomeDetail from "@/views/desktop/WayHome/WayHomeDetail";
import MobileWayHomeMap from "@/views/mobile/WayHome/WayHomeMap";
import MobileWayHomeDetail from "@/views/mobile/WayHome/WayHomeDetail";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import router from "@/router";
import 'swiper/css/swiper.css'

export default {
  name: 'WayHome2',
  components: {
    WayHomeSearchCollection,
    WayHomeMap,
    WayHomeDetail,
    MobileWayHomeMap,
    MobileWayHomeDetail,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      collection: undefined,
      seekTime: 0,
      isDetailShow: false,
      isMapShow: false,
      isSearchShow: false,
      usePopup: true,
      audioController: undefined,
      audioModel: {
        duration: 0,
        currentTime: 0,
        isPlaying: false
      },
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      currentLanguageKey: this.$store.getters.languageKey,
      showTooltip: false
    }
  },
  filters: {
    durationFilter: function (totalSeconds) {
      const minutes = parseInt(totalSeconds / 60)
      const seconds = parseInt(totalSeconds % 60)
      return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    }
  },
  computed: {
    isSamePlayingCollection() {
      return this.collection && this.$store.getters.playingCollectionId === this.collection.CollectionId
    },
    isPlaying() {
      return this.isSamePlayingCollection ? this.$store.getters.isAudioPlaying : false
    },
    currentTime() {
      if (this.$store.getters.audioPlayerCurrentTime >= 60) {
        this.$store.commit('AddAudioRecord', this.$store.getters.playingCollectionId)
      }
      return this.isSamePlayingCollection ? this.$store.getters.audioPlayerCurrentTime : 0
    },
    durationTime() {
      return this.isSamePlayingCollection ? this.$store.getters.audioPlayerDuration : 0
    },
    remainTime() {
      if (this.isSamePlayingCollection) {
        const remainSeconds = this.durationTime - this.currentTime
        const minutes = parseInt(remainSeconds / 60)
        const seconds = parseInt(remainSeconds % 60)
        return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
      } else {
        return '00:00'
      }
    }
  },
  watch: {
    currentTime(newTime) {
      // console.log(`current: ${oldTime} > ${newTime}`)
      this.audioModel.currentTime = newTime
    }
  },
  mounted () {
    this.$store.commit('LoadingOpen')
    this.$store.dispatch('GetCollectionList').then(res => {
      if (res.success) {
        this.collection = res.collections.filter(x => x.CollectionId === parseInt(this.$route.params.id))[0]
        if (this.collection === null) {
          alert('查無導覽')
          this.$router.push({
            name: 'DesktopWayHome1'
          })
          return
        }
        const detail = this.collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
        this.$store.dispatch('SendClickEvent', {
          controller: 'Collection',
          id: this.collection.CollectionId,
          detailId: detail.CollectionDetailId,
          languageKey: this.currentLanguageKey
        })
        this.$store.dispatch('PlayAudioPlayer', {
          collectionData: this.collection,
          collectionId: this.collection.CollectionId,
          lang: this.currentLanguageKey,
          autoPlay: false
        }).then(() => {
          this.$store.commit('LoadingClose')
        }).catch(() => {
          this.$store.commit('LoadingClose')
        })
      } else {
        this.$store.commit('LoadingClose')
      }
    })
  },
  methods: {
    changeCollection(collection) {
      this.isSearchShow = false
      this.$store.commit('LoadingOpen')
      this.collection = collection
      this.$store.dispatch('PlayAudioPlayer', {
        collectionData: this.collection,
        collectionId: this.collection.CollectionId,
        lang: this.currentLanguageKey,
        autoPlay: false
      }).then(() => {
        this.$store.commit('LoadingClose')
      })
    },
    getTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.Title
    },
    getSubTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.SubTitle
    },
    getHighlightCollectionTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      if (detail === null) {
        return ''
      } else if (detail.SubTitle && detail.SubTitle.length > 0) {
        return detail.SubTitle
      } else {
        return detail.Title
      }
    },
    getProfessionalName(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.ProfessionalName
    },
    getProfessionalUnit(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.ProfessionalUnit
    },
    getAudioFilePath(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail !== null && detail.AudioFilePath.length > 0 ? detail.AudioFilePath : null
    },
    handleSetupAudio() {
      const _this = this
      this.audioController = new Audio(`https://homing.ntsec.gov.tw/files/${this.getAudioFilePath(this.collection)}`)
      this.audioController.onloadedmetadata = function() {
        _this.audioModel.duration = parseInt(_this.audioController.duration)
      };
      this.audioController.onplay = function() {
        console.log("Video is playing");
        _this.audioModel.isPlaying = true
      };
      this.audioController.onpause = function() {
        console.log("Video is paused");
        _this.audioModel.isPlaying = false
      };
      this.audioController.onended = function() {
        console.log("Video is onended");
        _this.audioModel.isPlaying = false
        _this.audioModel.currentTime = 0
        _this.handleChangeSpeed(1.0)
      };
      this.audioController.ontimeupdate = function() {
        console.log(_this.audioController.currentTime)
        _this.audioModel.currentTime = _this.audioController.currentTime
      };
    },
    handlePlayAudio() {
      if (this.$store.getters.isAudioPlaying) {
        this.$store.dispatch('PauseAudioPlayer')
      } else {
        this.$store.dispatch('RePlayAudioPlayer')
        // this.$store.commit('LoadingOpen')
        // this.$store.dispatch('PlayAudioPlayer', {
        //   collectionData: this.collection,
        //   collectionId: this.collection.CollectionId,
        //   lang: this.currentLanguageKey,
        //   autoPlay: true
        // }).then(res => {
        //   this.$store.commit('LoadingClose')
        //   if (res.success) {
        //     this.$store.commit('AddAudioRecord', this.collection.CollectionId)
        //   } else {
        //     alert(res.message)
        //   }
        // })
      }
    },
    handleSliderInput() {
      this.showTooltip = true
    },
    handleSliderChange(val) {
      this.$store.dispatch('SeekToAudioPlayer', { currentTime: val })
      this.showTooltip = false
    },
    handleSliderFormatTooltip(totalSeconds) {
      const minutes = parseInt(totalSeconds / 60)
      const seconds = parseInt(totalSeconds % 60)
      return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    },
    handleChangeSpeed(speed) {
      this.$store.dispatch('RateToAudioPlayer', { rate: speed })
    },
    goNext () {
    },
    goBack() {
      router.back()
    },
    goDetail () {
    },
    goMap () {
    }
  }
}
