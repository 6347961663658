<template>
  <div class="main-menu">
    <div class="sub-menu" @click="goNext('DesktopWayHome0')">
      <div class="sub-menu-title-container">
        <div class="sub-menu-title sub-menu-title-zh">找家<br>之路</div>
        <div class="sub-menu-title sub-menu-title-en">Ｗay Home<br></div>
        <div class="enter-btn">
          <img src="../../assets/images/icon-arrow-right.svg" alt="進入">
        </div>
      </div>
    </div>
    <div class="sub-menu" @click="goNext('DesktopExploration0')">
      <div class="sub-menu-title-container">
        <div class="sub-menu-title sub-menu-title-zh">互動<br>探索</div>
        <div class="sub-menu-title sub-menu-title-en">Exploration<br></div>
        <div class="enter-btn">
          <img src="../../assets/images/icon-arrow-right.svg" alt="進入">
        </div>
      </div>
    </div>
    <div class="sub-menu" @click="goNext('DesktopAdventureArchive')">
      <div class="sub-menu-title-container">
        <div class="sub-menu-title sub-menu-title-zh">探索<br>檔案</div>
        <div class="sub-menu-title sub-menu-title-en">Advantutre<br>Archive</div>
        <div class="enter-btn">
          <img src="../../assets/images/icon-arrow-right.svg" alt="進入">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../router'

export default {
  name: 'MainMenu',
  components: {
  },
  methods: {
    /***
     * @param routerName
     */
    goNext (routerName) {
      router.push({
        name: routerName
      })
      // console.log(routerName)
      // router.push({
      //   name: 'Exploration3D',
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-menu {
  width: 100vw;
  height: 100vh;
  display: flex;
  .sub-menu {
    width: 33.33%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sub-menu-title-container {
      width: 70%;
      //display: none;
      .sub-menu-title {
        font-size: 3.0em;
        color: #BCD7CE;
        text-align: left;
      }
      .sub-menu-title-zh {
        font-size: 3.0em;
        font-weight: 700;
        padding: 12px 24px;
        text-decoration: underline;
        text-decoration-color: #BCD7CE;
        text-underline-offset: 0.5em;
        text-align: left;
        line-height: 5.5rem;
        text-decoration-thickness: 3%;
      }
      .sub-menu-title-en {
        font-weight: 400;
        font-size: 1.8em;
        //font-family: DS-Digital, serif;
        padding: 0 24px;
        min-height: 5rem;
      }
      .enter-btn {
        text-align: left;
        margin: 40px 24px 0 24px;
        img {
          width: 28%;
        }
      }
    }
  }
  .sub-menu:nth-child(1) {
    background-color: #252E35;
    //background-image: url("../assets/images/menu-1.svg");
  }
  .sub-menu:nth-child(2) {
    background-color: #20282F;
    //background-image: url("../assets/images/menu-2.svg");
  }
  .sub-menu:nth-child(3) {
    background-color: #1B2228;
    //background-image: url("../assets/images/menu-3.svg");
  }
}
</style>
