var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-main-menu"},[_c('div',{staticClass:"sub-menu",on:{"click":function($event){return _vm.goNext('MobileExploration3DList')}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"sub-menu",on:{"click":function($event){return _vm.goNext('MobileExplorationARList')}}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"sub-menu",on:{"click":_vm.goToArFish}},[_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-menu-title-container"},[_c('div',{staticClass:"sub-menu-title sub-menu-title-zh"},[_vm._v("動物"),_c('br'),_vm._v("模型")]),_c('div',{staticClass:"sub-menu-title sub-menu-title-en"},[_vm._v("Animal"),_c('br'),_vm._v("Model")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter-btn"},[_c('img',{attrs:{"src":require("../../../assets/images/icon-arrow-right.svg"),"alt":"進入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-menu-title-container"},[_c('div',{staticClass:"sub-menu-title sub-menu-title-zh"},[_vm._v("AR"),_c('br'),_vm._v("互動")]),_c('div',{staticClass:"sub-menu-title sub-menu-title-en"},[_vm._v("Augumented"),_c('br'),_vm._v("Reality")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter-btn"},[_c('img',{attrs:{"src":require("../../../assets/images/icon-arrow-right.svg"),"alt":"進入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-menu-title-container"},[_c('div',{staticClass:"sub-menu-title sub-menu-title-zh"},[_vm._v("模擬"),_c('br'),_vm._v("動畫")]),_c('div',{staticClass:"sub-menu-title sub-menu-title-en"},[_vm._v("Simulating"),_c('br'),_vm._v("Animation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter-btn"},[_c('img',{attrs:{"src":require("../../../assets/images/icon-arrow-right.svg"),"alt":"進入"}})])
}]

export { render, staticRenderFns }