<template>
  <div class="exploration-3D">
<!--    <model-obj :src="`https://homing.ntsec.gov.tw/files/${selectedInteraction.ModelFileName}`" backgroundColor="#000000"></model-obj>-->
    <model-gltf
        :src="getModelUrl"
        :backgroundColor="backgroundColor"
        :rotation="modelRotation"
        @on-load="handleLoaded"
    ></model-gltf>
<!--    <model-obj-->
<!--        :src="getModelUrl"-->
<!--        :mtl="getModelMTLUrl"-->
<!--        :backgroundColor="backgroundColor"-->
<!--        :cameraPosition="cameraPosition"-->
<!--        style="display: none"-->
<!--    ></model-obj>-->
    <div v-if="showTip" class="model-control-tip" @click="showTip = false">
      <img class="img-icon-360" src="../../../assets/images/icon-360.svg" alt="360拖曳可旋轉">
      <div class="control-tip-span font-weight-light">
        <span>{{ getTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Exploration3dMixin from "@/mixins/exploration-3d-mixin";

export default {
  name: 'Exploration3D',
  mixins: [Exploration3dMixin]
}
</script>

<style lang="scss" scoped>
.exploration-3D {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #20272D;
  overflow: hidden;
  .model-control-tip {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    .img-icon-360 {
      position: absolute;
      width: 150px;
      height: 150px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    .control-tip-span {
      position: absolute;
      width: 100vw;
      right: 0;
      bottom: 50px;
      left: 0;
      margin: auto;
      text-align: center;
      color: #BCD7CE;
    }
  }
}
</style>
