<template>
  <div class="exploration-0">
    <div class="desktop-flashlight-container">
      <div class="desktop-flashlight-title font-weight-medium">
        <span>{{ getTitle }}<br>{{ getSubTitle }}</span>
      </div>
      <div class="desktop-flashlight-enter-button font-weight-bold">
        <span>{{ getEnterBtnTitle }}</span>
        <img src="../../../assets/images/icon-arrow-right-black.svg" alt>
      </div>
    </div>
    <img
        id="flashlight"
        src="../../../assets/images/flashlight.svg"
        alt="flashlight"
        class="flashlight"
        :style="{
            left: flashlightPointData.Left + 'px',
            top: flashlightPointData.Top + 'px',
            width: flashlightPointData.Width + 'px',
            height: flashlightPointData.Height + 'px'
          }"
        @load="flashlightLoaded">
    <div
        style="position: absolute; border: 1px solid white; width: 18vw; height: 12vh; pointer-events: none; visibility: hidden"
        :style="{
            left: (flashlightPointData.Left + (flashlightPointData.Width / 2) - 100) + 'px',
            top: (flashlightPointData.Top + (flashlightPointData.Height / 2) - 50) + 'px',
          }"
    ></div>
    <div
        style="position: absolute; border: 0px solid red; width: 15vw; height: 8vh; right: 15%; bottom: 43%;"
        @click="goNext"
    ></div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'

export default {
  name: 'Exploration0',
  components: {},
  data() {
    return {
      tapDown: {
        x: 0,
        y: 0,
        centerX: 0,
        centerY: 0,
        distance: 0,
        scale: 1,
        rotate: 0,
        touchStartAngle: 0,
        startAngle: 0
      },
      flashlightPointData: {
        Left: -document.body.offsetWidth / 2,
        Top: -document.body.offsetHeight / 2,
        Width: document.body.offsetWidth * 2,
        Height: document.body.offsetHeight * 2,
      },
      originFlashlightPointData: null,
      bodyOffsetWidth: document.body.offsetWidth,
      bodyOffsetHeight: document.body.offsetHeight,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  computed: {
    getTitle() {
      if (this.currentLanguageKey === 'ZH_TW') {
        return '體驗不同的互動遊戲，'
      } else {
        return 'Join in interactive games for an exciting'
      }
    },
    getSubTitle() {
      if (this.currentLanguageKey === 'ZH_TW') {
        return '為你的家園探索旅程增加更多樂趣！'
      } else {
        return 'adventure into the heart of home land.'
      }
    },
    getEnterBtnTitle() {
      if (this.currentLanguageKey === 'ZH_TW') {
        return '探索'
      } else {
        return 'Explore'
      }
    },
  },
  mounted() {
    this.bodyOffsetWidth = document.body.offsetWidth
    this.bodyOffsetHeight = document.body.offsetHeight
    this.flashlightPointData = {
      Left: -document.body.offsetWidth * 0.8,
      Top: -document.body.offsetHeight * 0.5,
      Width: document.body.offsetWidth * 2,
      Height: document.body.offsetHeight * 2,
    }
    console.log(`screen ${this.bodyOffsetWidth} x ${this.bodyOffsetHeight}`)
    console.log(`body ${document.body.offsetWidth} x ${document.body.offsetHeight}`)
  },
  beforeDestroy() {
    const photoElement = document.getElementById('flashlight')
    if (photoElement) {
      photoElement.removeEventListener('mousemove')
      photoElement.removeEventListener('mouseup')
      photoElement.removeEventListener('mouseleave')
      photoElement.removeEventListener('mousedown')
      photoElement.removeEventListener('touchstart')
      photoElement.removeEventListener('touchmove')
      photoElement.removeEventListener('touchend')
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goNext() {
      if (!(this.flashlightPointData.Left > this.flashlightPointData.Width * 0.5 * -0.39 &&
          this.flashlightPointData.Top > this.flashlightPointData.Height * 0.5 * -0.45)) {
        router.push({
          name: 'DesktopExploration1'
        })
      }
    },
    flashlightLoaded() {
      const photoElement = document.getElementById('flashlight')
      photoElement.addEventListener('mousemove', this.photoTouchMove, { passive: false })
      photoElement.addEventListener('mouseup', this.photoTouchEnd, { passive: false })
      photoElement.addEventListener('mouseleave', this.photoTouchEnd, { passive: false })
      photoElement.addEventListener('mousedown', this.photoTouchStart, { passive: false })
      photoElement.addEventListener('touchstart', this.photoTouchStart, { passive: false })
      photoElement.addEventListener('touchmove', this.photoTouchMove, { passive: false })
      photoElement.addEventListener('touchend', this.photoTouchEnd, { passive: false })
    },
    photoTouchStart (e) {
      e.preventDefault()
      if (e.touches && e.touches.length === 1) {
        this.tapDown.x = e.touches[0].pageX
        this.tapDown.y = e.touches[0].pageY
      } else if (e.pageX) {
        this.tapDown.x = e.pageX
        this.tapDown.y = e.pageY
      }

      console.log('photoTouchStart')
      console.log(e)

      let flashlightCenterX = this.flashlightPointData.Left + (this.flashlightPointData.Width / 2);
      let flashlightCenterY = this.flashlightPointData.Top + (this.flashlightPointData.Height / 2);
      console.log(`flashlightCenterX:${flashlightCenterX}, flashlightCenterY:${flashlightCenterY}`)
      console.log(`areaX:${Math.abs(this.tapDown.x - flashlightCenterX)}, areaY:${Math.abs(this.tapDown.y - flashlightCenterY)}`)
      if (Math.abs(this.tapDown.x - flashlightCenterX) < (this.bodyOffsetWidth * 0.18 / 2) && Math.abs(this.tapDown.y - flashlightCenterY) < (this.bodyOffsetHeight * 0.12 / 2)) {
        console.log('in area')
        this.originFlashlightPointData = JSON.parse(JSON.stringify(this.flashlightPointData))
      } else {
        console.log('not in area')
        this.originFlashlightPointData = null
      }
    },
    photoTouchMove (e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.originFlashlightPointData === null) {
        return
      }
      let updateLeft = 0
      let updateTop = 0
      if (e.touches && e.touches.length === 1) {
        updateLeft = this.originFlashlightPointData.Left + (e.touches[0].pageX - this.tapDown.x)
        updateTop = this.originFlashlightPointData.Top + (e.touches[0].pageY - this.tapDown.y)
      } else if (e.pageX) {
        updateLeft = this.originFlashlightPointData.Left + (e.pageX - this.tapDown.x)
        updateTop = this.originFlashlightPointData.Top + (e.pageY - this.tapDown.y)
      }
      console.log(`updateLeft: ${updateLeft}, updateTop: ${updateTop}`)

      if ((updateLeft + (this.flashlightPointData.Width / 2) - (this.bodyOffsetWidth * 0.18 / 2)) >= 0 &&
          (updateLeft + (this.flashlightPointData.Width / 2) + (this.bodyOffsetWidth * 0.18 / 2)) <= this.bodyOffsetWidth &&
          (updateTop + (this.flashlightPointData.Height / 2) - (this.bodyOffsetHeight * 0.12 / 2)) >= 0 &&
          (updateTop + (this.flashlightPointData.Height / 2) + (this.bodyOffsetHeight * 0.12 / 2)) <= this.bodyOffsetHeight
      ) {
        console.log('photoTouchMove update')
        this.flashlightPointData.Left = updateLeft
        this.flashlightPointData.Top = updateTop
        console.log(JSON.stringify(this.flashlightPointData))
      }
    },
    photoTouchEnd (e) {
      e.preventDefault()
      this.originFlashlightPointData = null
    }
  }
}
</script>

<style lang="scss" scoped>
.exploration-0 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/images/exploration-0-bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #07DB95;
  overflow: hidden;
  .desktop-flashlight-container {
    position: absolute;
    left: 15vw;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    width: 60vw;
    height: 80px;
    margin: auto;
    text-align: left;
    .desktop-flashlight-title {
      flex: 1;
      font-size: 1.6rem;
      color: #20272D;
      line-height: 40px;
    }
    .desktop-flashlight-enter-button {
      text-align: right;
      font-size: 1.6rem;
      color: #1F262D;
      //margin: 20px 10vw;
      height: 40px;
      line-height: 40px;
      animation: fade 1s infinite;
      -webkit-animation: fade 1s infinite;
      padding-top: 40px;
      img {
        margin: 8px 20px;
      }
    }
  }
  .enter-button {
    position: absolute;
    border: 0 solid red;
    width: 18vw;
    height: 8vh;
    right: 20%;
    bottom: 40%;
    max-height: 45px;
  }
  .flashlight {
    position: absolute;
    left: -50%;
    right: -50%;
    top: -50%;
    bottom: -50%;
    width: 200%;
    height: 200%;
    object-fit: cover;
    //display: none;
    //pointer-events: none;
  }
}
</style>

