import axios from "axios";
import router from "@/router";

export default {
  name: 'WayHomeDetail',
  components: {
  },
  props: {
    dataId: {
      type: Number
    }
  },
  computed: {
    getDetail() {
      return this.collection ? this.collection.CollectionDetails.filter(d => d.LanguageKey === this.$store.getters.languageKey)[0] : null
    },
    getDetailImage() {
      const detail = this.getDetail
      if (detail && detail.CollectionDetailAudios.length > 0) {
        return `https://homing.ntsec.gov.tw/files/${detail.CollectionDetailAudios[0].ImagePath}`
      }
      return ''
    },
    getDetailImageIntro() {
      const detail = this.getDetail
      if (detail && detail.CollectionDetailAudios.length > 0) {
        return detail.CollectionDetailAudios[0].Introduction
      }
      return ''
    },
    showImage() {
      return (this.$store.getters.playingCollectionId === parseInt(this.collectionId) && this.$store.getters.showAudioImage && this.$store.getters.showAudioImage.ImagePath.trim().length > 0) ?
        ('https://homing.ntsec.gov.tw/files/' + this.$store.getters.showAudioImage.ImagePath) : this.getDetailImage
      // 'https://homing.ntsec.gov.tw/files/' + filter.ImagePath
    },
    showImageName() {
      return (this.$store.getters.showAudioImage) ?
        (this.$store.getters.showAudioImage.Introduction) : this.getDetailImageIntro
    }
  },
  watch: {
  },
  data() {
    return {
      collectionId: undefined,
      collection: undefined,
      scaleDetailImg: false,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  mounted () {
    if (this.dataId !== null) {
      this.collectionId = this.dataId
    } else if (this.$route.params.id) {
      this.collectionId = this.$route.params.id
    }

    if (this.collectionId) {
      axios.get('https://homing.ntsec.gov.tw/api/Collection/Info/' + this.$route.params.id).then(res => {
        console.log(res)
        this.collection = res.data.Result
      })
    }
  },
  methods: {
    getTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.Title
    },
    getProfessionalName(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.ProfessionalName
    },
    getHighlightContent(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.HighlightContent
    },
    getMainContent(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.MainContent
    },
    goBack() {
      if (this.dataId) {
        this.$emit('close')
      } else {
        router.back()
      }
    }
  }
}
