import {ModelGltf} from "vue-3d-model";

export default {
  components: {
    ModelGltf,
    // ModelObj
  },
  data() {
    return {
      interactionList: [],
      selectedInteraction: null,
      backgroundColor: '#A9A9A9',
      modelRotation: {
        x: 90,
        y: -80,
        z: 0
      },
      showTip: true,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  computed: {
    getModelUrl() {
      if (this.selectedInteraction && this.selectedInteraction.ModelFileName) {
        const fileName = this.selectedInteraction.ModelFileName
        if (process.env.NODE_ENV === 'development') {
          // return '/F30_耳廓狐.gltf'
          return '/Y1-L.gltf'
        } else if (this.selectedInteraction) {
          return `https://homing.ntsec.gov.tw/files/${fileName.replace('.obj', '.gltf')}`
        } else {
          return ''
        }
      }
      return ''
    },
    getModelMTLUrl() {
      if (process.env.NODE_ENV === 'development') {
        return '/Y62-L.mtl'
      } else if (this.selectedInteraction) {
        return `https://homing.ntsec.gov.tw/files/${this.selectedInteraction.ModelFileName}`
      } else {
        return ''
      }
    },
    getTitle() {
      if (this.currentLanguageKey === 'ZH_TW') {
        return '試著上下、左右旋轉模型，觀賞更多角度細節。'
      } else {
        return 'Rotate the model in all directions to observe it at different angles.'
      }
    }
  },
  mounted() {
    this.$store.commit('LoadingOpen')
    this.$store.dispatch('GetInteractionList').then(res => {
      if (res.success) {
        try {
          this.interactionList = res.interactions.filter(x => x.InteractionType === 0)
          const filterList = this.interactionList.filter(x => x.InteractionId === parseInt(this.$route.query.id))
          const model = filterList[0] ? filterList[0] : this.interactionList[0]
          const detail = model.InteractionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
          this.$store.dispatch('SendClickEvent', {
            controller: 'Interaction',
            id: model.InteractionId,
            detailId: detail.InteractionDetailId,
            languageKey: this.currentLanguageKey
          })


          if (model.ModelRotation) {
            const values = model.ModelRotation.split(',')
            this.modelRotation = {
              x: values[0],
              y: values[1],
              z: values[2]
            }
          }
          this.selectedInteraction = model
        } catch (e) {
          this.$store.commit('LoadingClose')
          alert(e.toString())
        }
      }
    })
  },
  methods: {
    handleLoaded() {
      this.$store.commit('LoadingClose')
    }
  }
}
