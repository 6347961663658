<template>
  <div class="main-menu">
    <div class="sub-menu" @click="goNext('DesktopExploration3DList')">
      <div class="sub-menu-title-container">
        <div class="sub-menu-title sub-menu-title-zh">動物<br>模型</div>
        <div class="sub-menu-title sub-menu-title-en">Animal<br>Model</div>
        <div class="enter-btn">
          <img src="../../../assets/images/icon-arrow-right.svg" alt="進入">
        </div>
      </div>
    </div>
    <div class="sub-menu" @click="goNext('DesktopExplorationARList')">
      <div class="sub-menu-title-container">
        <div class="sub-menu-title sub-menu-title-zh">AR<br>互動</div>
        <div class="sub-menu-title sub-menu-title-en">Augumented<br>Reality</div>
        <div class="enter-btn">
          <img src="../../../assets/images/icon-arrow-right.svg" alt="進入">
        </div>
      </div>
    </div>
    <div class="sub-menu" @click="goToArFish">
      <div class="sub-menu-title-container">
        <div class="sub-menu-title sub-menu-title-zh">模擬<br>動畫</div>
        <div class="sub-menu-title sub-menu-title-en">Simulating<br>Animation</div>
        <div class="enter-btn">
          <img src="../../../assets/images/icon-arrow-right.svg" alt="進入">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'

export default {
  name: 'Exploration1',
  components: {
  },
  methods: {
    /***
     * @param routerName
     */
    goNext (routerName) {
      router.push({
        name: routerName
      })
    },
    goToArFish() {
      this.$store.commit('LoadingOpen')
      this.$store.dispatch('GetInteractionList').then(res => {
        this.$store.commit('LoadingClose')
        if (res.success) {
          try {
            const interaction = res.interactions.filter(x => x.InteractionType === 1 && x.FileSize === 'CUTTLE')[0]
            router.push({
              name: 'DesktopExplorationARCUTTLEFISH',
              query: {
                id: interaction.InteractionId
              }
            })
          } catch (e) {
            alert(e.toString())
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-menu {
  width: 100vw;
  height: 100vh;
  display: flex;
  .sub-menu {
    width: 33.33%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sub-menu-title-container {
      width: 70%;
      .sub-menu-title {
        font-size: 3.0em;
        color: #BCD7CE;
        text-align: left;
      }
      .sub-menu-title-zh {
        font-size: 3.0em;
        font-weight: 700;
        padding: 12px 24px;
        text-decoration: underline;
        text-decoration-color: #BCD7CE;
        text-underline-offset: 0.5em;
        text-align: left;
        line-height: 5.5rem;
        text-decoration-thickness: 3%;
      }
      .sub-menu-title-en {
        font-weight: 400;
        font-size: 1.8em;
        //font-family: DS-Digital, serif;
        padding: 0 24px;
        min-height: 5rem;
      }
      .enter-btn {
        text-align: left;
        margin: 40px 24px 0 24px;
        img {
          width: 28%;
        }
      }
    }
  }
  .sub-menu:nth-child(1) {
    background-color: #007958;
    //background-image: url("../../assets/images/exploration-1-1.svg");
  }
  .sub-menu:nth-child(2) {
    background-color: #00604A;
    //background-image: url("../../assets/images/exploration-1-2.svg");
  }
  .sub-menu:nth-child(3) {
    background-color: #004A3F;
    //background-image: url("../../assets/images/exploration-1-3.svg");
  }
}
</style>
