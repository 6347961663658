export default {
  data() {
    return {
      cameraImage: '',
      similarity: undefined,
      canvas: undefined,
      video: undefined,
      timer: undefined,
      isDetected: false,
      image1: undefined,
      image2: undefined,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  mounted() {
    console.log(this.$router)
    console.log(this.$route)
    // get mobile current orientation
    this.handleOrientationChange();
    // detect mobile device is portrait or landscape
    window.addEventListener('orientationchange', this.handleOrientationChange);
    if (process.env.NODE_ENV === 'development') {
      this.isDetected = true
      this.$store.commit('AddInteractionRecord', this.$route.name)
    } else {
      this.initCamera()
      const timeout = this.getRandom(3000, 6000)
      console.log(timeout)
      const that = this
      setTimeout(function () {
        that.isDetected = true
        that.$store.commit('AddInteractionRecord', that.$route.name)
        clearInterval(that.timer)
      }, timeout);
      document.getElementById('CompareImage').onload = () => {
        this.image2 = window.cv.imread(document.getElementById('CompareImage'));
      };
    }
  },
  beforeDestroy() {
    // get mobile camera stream



    console.log('beforeDestroy')
    const videoStreams = this.video.srcObject.getVideoTracks()

    videoStreams.forEach(stream => {
      stream.stop() // 停止所有media stream
    });

    // 釋放資源
    this.video.src = this.video.srcObject = null;
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getRandom(min,max) {
      return Math.floor(Math.random()*(max-min+1))+min;
    },
    initCamera() {
      this.canvas = document.getElementById('CameraCanvas')
      this.video = document.getElementById('video');
      // var text = document.getElementById('text');
      // var mediaStreamTrack;

      // 兼容代码
      window.URL = (window.URL || window.webkitURL || window.mozURL || window.msURL);

      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        }
      }

      //摄像头调用配置
      var mediaOpts = {
        audio: false,
        // video: true,
        video: { facingMode: "environment"} // 或者 "user"
        // video: { width: 1280, height: 720 }
        // video: { facingMode: { exact: "environment" } }// 或者 "user"
      }

      let that=this;
      navigator.mediaDevices.getUserMedia(mediaOpts).then(function(stream) {
        that.mediaStreamTrack = stream;
        // that.video = document.querySelector('video');
        if ("srcObject" in that.video) {
          that.video.srcObject = stream
        } else {
          that.video.src = window.URL && window.URL.createObjectURL(stream) || stream
        }
        that.video.play();
        that.timer = setInterval(function () {
          that.takePhone()
        }, 5000)
      }).catch(function (err) {
        console.log(err)
      });
    },
    takePhone() {//点击拍照截图画面
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, 400, 300) ;
      this.cameraImage = this.canvas.toDataURL('image/jpeg')
      // this.calculateSimilarity("CameraImage", "CompareImage").then()
      document.getElementById('CameraImage').onload = () => {
        this.image1 = window.cv.imread(document.getElementById('CameraImage'));
        this.compareImages()
      };
    },
    compareImages() {
      // 确保图像都已加载
      if (this.image1 && this.image2) {
        // 计算图像相似度（可以使用 OpenCV.js 提供的各种方法）
        // 这里使用直方图比较
        let hist1 = new window.cv.Mat();
        let hist2 = new window.cv.Mat();
        let compareMethod = window.cv.HISTCMP_CORREL; // 可以根据需要选择不同的比较方法

        window.cv.calcHist([this.image1], [0], new window.cv.Mat(), hist1, [256], [0, 256]);
        window.cv.calcHist([this.image2], [0], new window.cv.Mat(), hist2, [256], [0, 256]);

        this.similarity = window.cv.compareHist(hist1, hist2, compareMethod);

        // 释放内存
        hist1.delete();
        hist2.delete();
      }
    }
  }
}
