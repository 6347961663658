<template>
  <div class="mobile-way-home-2" v-if="collection">
    <div class="top-block">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide>
          <img class="carousel-item-img" :src="`https://homing.ntsec.gov.tw/files/${collection.ProfessionalPhoto}`" alt="對講機">-->
        </swiper-slide>
        <swiper-slide>
          <img class="carousel-item-img" :src="`https://homing.ntsec.gov.tw/files/${collection.ProfessionalPhoto2}`" alt="對講機">-->
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="bottom-block">
      <div class="collections-block">
        <div class="collection-control">
          <img src="../../../assets/images/icon-book.svg" alt="明細" @click="goDetail">
          <img src="../../../assets/images/icon-map.svg" alt="地圖" @click="goMap">
        </div>
        <div class="collection-no-title font-weight-medium">
          <span>{{ collection.CollectionNo }}</span>
          <span>{{ getTitle(collection) }}</span>
        </div>
        <div class="collection-sub-title font-weight-medium">
          <span>{{ getSubTitle(collection) }}</span>
        </div>
        <div class="collection-professionalName font-weight-medium">
          <span>ㄧ</span>
          <span>{{ getProfessionalName(collection) }}</span>
        </div>
        <div class="collection-professionalUnit font-weight-medium">
          <span></span>
          <span>{{ getProfessionalUnit(collection) }}</span>
        </div>
        <div v-if="getAudioFilePath(collection)" class="collection-player-container">
          <div class="collection-player">
            <img
                v-if="$store.getters.isAudioPlaying"
                class="icon-play"
                src="../../../assets/images/icon-audio-pause.svg"
                alt="暫停"
                @click="handlePlayAudio('audio_' + collection.CollectionId)">
            <img
                v-else
                class="icon-play"
                src="../../../assets/images/icon-audio-play.svg"
                alt="播放"
                @click="handlePlayAudio('audio_' + collection.CollectionId)">
            <el-slider
                class="icon-slider"
                v-model="audioModel.currentTime"
                :max="$store.getters.audioPlayerDuration"
                :format-tooltip="handleSliderFormatTooltip"
                @input="handleSliderInput"
                @change="handleSliderChange"></el-slider>
            <div class="seek-time">
<!--              <span>{{ audioModel.duration | durationFilter }}</span>-->
              <span>{{ remainTime }}</span>
            </div>
          </div>
          <div class="collection-player-speed-container">
            <el-button type="text" @click="handleChangeSpeed(1.0)">1 x</el-button>
            <el-divider direction="vertical" />
            <el-button type="text" @click="handleChangeSpeed(1.25)">1.25 x</el-button>
            <el-divider direction="vertical" />
            <el-button type="text" @click="handleChangeSpeed(1.5)">1.5 x</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="right-top-search-button" @click="isSearchShow = true">
      <img src="../../../assets/images/icon-collection-search.svg" alt>
    </div>
    <div v-if="isSearchShow" class="popup-fixed" style="overflow: hidden">
      <way-home-search-collection @close="isSearchShow = false;" @searched="changeCollection"></way-home-search-collection>
    </div>
    <div v-if="isDetailShow" class="popup-fixed">
      <mobile-way-home-detail :data-id="collection.CollectionId"  @close="isDetailShow = false; $store.commit('ForceShowFixedAudioPlayer', false);"></mobile-way-home-detail>
    </div>
    <div v-if="isMapShow" class="popup-fixed" style="overflow: hidden">
      <mobile-way-home-map :data-url="collection.MapImage" @close="isMapShow = false; $store.commit('ForceShowFixedAudioPlayer', false);"></mobile-way-home-map>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'
import WayHome2Mixin from "@/mixins/way-home-2-mixin";


export default {
  name: 'WayHome2',
  mixins: [WayHome2Mixin],
  components: {
  },
  data() {
    return {
    }
  },
  filters: {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  beforeDestroy() {
    this.$store.commit('SetBackButtonColor', undefined)
  },
  methods: {
    goNext () {
      router.push({
        name: 'MobileMainMenu'
      })
    },
    goDetail () {
      if (this.usePopup) {
        this.isDetailShow = true
        this.$store.commit('ForceShowFixedAudioPlayer', true)
      } else {
        router.push({
          name: 'MobileWayHomeDetail',
          params: {
            id: this.collection.CollectionId
          }
        })
      }
    },
    goMap () {
      if (this.usePopup) {
        this.isMapShow = true
        this.$store.commit('ForceShowFixedAudioPlayer', true)
      } else {
        router.push({
          name: 'MobileWayHomeMap',
          params: {
            map: encodeURIComponent(this.collection.MapImage)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-way-home-2 {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #20272D;
  //background-image: url("../../assets/images/bg.png");
  //background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  .top-block {
    position: relative;
    width: 100%;
    height: 55%;
  }
  .bottom-block {
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    height: 45%;
    .collections-block {
      padding: 30px 40px;
      margin: auto;
      color: white;
      display: flex;
      flex-direction: column;
      .collection-control {
        display: flex;
        height: 30px;
        img {
          height: 100%;
          margin-right: 20px;
        }
      }
      .collection-no-title {
        line-height: 30px;
        text-align: left;
        color: #CCDBCC;
        display: flex;
        margin-top: 10px;
        span {
          font-size: 1.3rem;
          min-width: 30px;
        }
        span:nth-child(1) {
          margin-right: 10px;
        }
      }
      .collection-sub-title {
        text-align: left;
        flex: 1;
        color: #B5D8CE;
        span {
          font-size: 1.8rem;
        }
      }
      .collection-professionalName {
        text-align: left;
        color: #B5D8CE;
        display: flex;
        margin-top: 10px;
        span {
          font-size: 1.3rem;
          min-width: 30px;
        }
        span:nth-child(1) {
          margin-right: 10px;
        }
      }
      .collection-professionalUnit {
        text-align: left;
        color: #5D9686;
        display: flex;
        margin-top: 3px;
        span {
          font-size: 1.2rem;
          min-width: 30px;
        }
        span:nth-child(1) {
          margin-right: 10px;
        }
      }
      .collection-player-container {
        padding: 20px 0;
        .collection-player {
          display: flex;
          height: 30px;
          line-height: 30px;
          .icon-play {
            padding: 5px;
          }
          .icon-slider {
            margin-left: 5px;
            width: calc(100% - 100px);
            .el-slider__bar {
              background-color: #07DB95;
            }
          }
          .seek-time {
            width: 50px;
            text-align: right;
            font-size: 20px;
            line-height: 30px;
            font-weight: lighter;
            color: #C1D6CE;
            margin-left: 5px;
          }
        }
        .collection-player-speed-container {
          display: flex;
          justify-content: center;/*水平置中*/
          align-items: center; /*垂直置中*/
          .el-button {
            color: #07DB95;
            font-family: "Droid Sans", Sans-serif, serif;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            margin: 0 5px;
          }
          .el-divider--vertical {
            height: 1.3em;
            background-color: #4D565E;
          }
        }
      }
    }
    .collections-list-block:hover {
      background-color: #5E927A;
    }
  }
  .right-top-search-button {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 55px;
    height: 55px;
    z-index: 100;
    img {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.mobile-way-home-2 {
  .swiper-container {
     height: 100%;
     width: 100%;
     margin: auto;
    .swiper-wrapper {
      .swiper-slide {
        height: 95%;
        border-bottom-left-radius: 30vw;
        border-bottom-right-radius: 30vw;
        overflow: hidden;
        img {
          width: 99%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: center bottom;
          object-position: center bottom;
        }
      }
    }
    .swiper-pagination {
      bottom: 0 !important;
      .swiper-pagination-bullet {
        background: #c0c4cc;
        opacity: 0.24;
      }
      .swiper-pagination-bullet-active {
        opacity: 0.64;
      }
    }
   }
  .icon-slider {
    margin-left: 20px;
    width: calc(100% - 130px);
    .el-slider__runway {
      background-color: #40484F;
      border-radius: 0;
      height: 8px;
      margin: 11px 0;
      .el-slider__bar {
        background-color: #07DB95;
        border-radius: 0;
      }
      .el-slider__button {
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
}
</style>
