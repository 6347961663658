import ArMixin from "@/mixins/ar-mixin";

export default {
  mixins: [ArMixin],
  data() {
    return {
      showInfoContainer: false
    }
  },
  mounted() {
    const _this = this
    setTimeout(function () {
      _this.showInfoContainer = true
    }, 12000)
  },
  computed: {
    getTitle() {
      if (this.currentLanguageKey === 'ZH_TW') {
        return '潛水員在海裡置放竹欉、協助軟絲產卵，成功復育的軟絲卵鞘，吸引海龜等生物前來覓食。\n' +
          '人類如何在自然界扮演平衡的角色、保護海底生態環境，還有許多的挑戰，等著我們去理解和努力。'
      } else {
        return 'Divers place bamboo bundles underwater for bigfin reef squids to spawn oothecae, which can attract sea turtles hunting for food. \n' +
          'The role of humans in nature – balancing ecosystems and protecting marine environments – is a mystery that requires us to continue to evolve our understanding and work hard to resolve.'
      }
    }
  },
  methods: {
    handleOrientationChange() {
      if (window.orientation === 0 || window.orientation === 180) {
        alert('請將裝置橫放掃描')
      } else {
        // this.$router.push({ name: 'Landscape' })
      }
    }
  }
}
