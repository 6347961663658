<template>
  <div style="overflow: hidden; position: relative">
    <video id="video" class="camera"></video>
    <div v-if="!isDetected" class="compare-container">
      <img id="CameraImage" :src="cameraImage" style="visibility: hidden" alt>
      <img id="CompareImage" src="../../../../assets/images/ar-cuttle-compare.jpg" style="visibility: hidden" alt>
      <canvas id="CameraCanvas"></canvas>
      <!--      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; width: 300px; height: 50px; color: red; font-size: 30px">-->
      <!--        相似度: {{ similarity }}-->
      <!--      </div>-->
    </div>
    <div v-else class="ar-container">
      <img src="../../../../assets/images/ar-cuttle/Bamboo.gif" alt class="ar-bamboo ar-bamboo-1">
      <img src="../../../../assets/images/ar-cuttle/Bamboo.gif" alt class="ar-bamboo ar-bamboo-2">
      <img src="../../../../assets/images/ar-cuttle/diver.gif" alt class="ar-diver ar-diver-1">
      <img src="../../../../assets/images/ar-cuttle/diver.gif" alt class="ar-diver ar-diver-2">
      <squid v-for="squidIndex in 7" :key="`Squid_${squidIndex}`" class="ar-squid" :class="`ar-squid-${squidIndex}`"></squid>
      <img src="../../../../assets/images/ar-cuttle/Turtoise.gif" alt class="ar-turtoise">
    </div>
<!--    <el-button type="danger" size="mini" style="position: absolute; right: 20px; bottom: 20px;" @click="reload">重新整理</el-button>-->
    <div v-if="showInfoContainer" class="info-container">
      <span class="font-weight-medium">{{ getTitle }}</span>
    </div>
  </div>
</template>

<script>
import Squid from "@/components/squid";
import ArCuttleMixin from "@/mixins/ar-cuttle-mixin";

export default {
  name: "AR-Cuttle",
  components: {Squid},
  mixins: [ArCuttleMixin],
  methods: {
    reload() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>

.camera {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
}
.compare-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.ar-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: black;
  .ar-diver {
    position: absolute;
    width: 300px;
    margin-left: 180px;
    margin-top: -80px;
    animation-duration: 10s;
    //animation-iteration-count: infinite;
  }
  .ar-diver-1 {
    top: -5vh;
    left: 0;
    animation-name: ar-diver-1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
  .ar-diver-2 {
    top: -20vh;
    left: -20vw;
    animation-name: ar-diver-2;
    animation-delay: 5s;
    animation-fill-mode: forwards;
  }
  .ar-bamboo {
    position: absolute;
    width: 700px;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    transform: translateY(-80px);
  }
  .ar-bamboo-1 {
    top: -20vh;
    left: -20vw;
    animation-delay: 1s;
    animation-name: ar-bamboo-1;
  }
  .ar-bamboo-2 {
    top: -23vh;
    left: -30vw;
    animation-delay: 5s;
    animation-name: ar-bamboo-2;
  }

  .ar-turtoise {
    position: absolute;
    left: 120vw;
    top: 100vh;
    width: 300px;
    animation-delay: 10s;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-name: ar-turtoise;
  }

  .ar-squid {
    position: absolute;
    opacity: 0;
    animation-delay: 10s;
    animation-iteration-count: infinite;
    background: transparent;
  }
  .ar-squid-1 {
    left: 10vw;
    top: 40vh;
    width: 120px;
    rotate: 40deg;
    animation-name: ar-squid-1;
    animation-duration: 15s;
    //animation-timing-function: ease-in;
  }
  .ar-squid-2 {
    right: 10vw;
    top: 3vh;
    width: 160px;
    rotate: -50deg;
    animation-name: ar-squid-2;
    animation-duration: 15s;
  }
  .ar-squid-3 {
    bottom: 10vw;
    right: 20vw;
    width: 180px;
    rotate: -20deg;
    animation-name: ar-squid-3;
    animation-duration: 15s;
  }
  .ar-squid-4 {
    left: 20vw;
    top: 30vh;
    width: 150px;
    rotate: -40deg;
    animation-name: ar-squid-4;
    animation-duration: 15s;
    //animation-timing-function: ease-in;
  }
  .ar-squid-5 {
    left: 15vw;
    top: 23vh;
    width: 80px;
    rotate: -80deg;
    animation-name: ar-squid-5;
    animation-duration: 15s;
    //animation-timing-function: ease-in;
  }
  .ar-squid-6 {
    left: 15vw;
    top: 55vh;
    width: 130px;
    rotate: -160deg;
    animation-name: ar-squid-6;
    animation-duration: 15s;
    //animation-timing-function: ease-in;
  }
  .ar-squid-7 {
    left: 40vw;
    top: 33vh;
    width: 150px;
    rotate: -160deg;
    animation-name: ar-squid-7;
    animation-duration: 15s;
    //animation-timing-function: ease-in;
  }
}

.info-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 30vw;
  width: 60%;
  margin: auto;
  padding: 40px;
  line-height: 2rem;
  background: rgba(0, 0, 0, 0.61);
  border-radius: 8px;
  span {
    color: #BCD7CE;
    font-size: 1.5rem;
  }
}

@keyframes ar-bamboo-1 {
  0%{
    top: -20vh;
    left: -20vw;
    rotate: 40deg;
  }
  30%{
    rotate: 40deg;
    transform: translateY(-80px);
  }
  40%{
    rotate: 30deg;
    transform: translateY(-70px);
  }
  60%{
    rotate: 20deg;
    transform: translateY(-50px);
  }
  80%{
    rotate: 10deg;
    transform: translateY(-30px);
  }
  90%{
    rotate: 10deg;
    transform: translateY(-20px);
  }
  100%{
    top: 40vh;
    left: 40vw;
    rotate: 0;
    transform: translateY(0);
  }
}

@keyframes ar-bamboo-2 {
  0%{
    top: -23vh;
    left: -30vw;
    rotate: 40deg;
  }
  30%{
    rotate: 40deg;
    transform: translateY(-80px);
  }
  40%{
    rotate: 30deg;
    transform: translateY(-70px);
  }
  60%{
    rotate: 20deg;
    transform: translateY(-50px);
  }
  80%{
    rotate: 10deg;
    transform: translateY(-30px);
  }
  90%{
    rotate: 10deg;
    transform: translateY(-20px);
  }
  100%{
    top: 20vh;
    left: 5vw;
    rotate: 0;
    transform: translateY(0);
  }
}

@keyframes ar-diver-1 {
  0%{
    top: -5vh;
    left: 0;
  }
  50% {
    top: 40vh;
    left: 50vw;
  }
  100% {
    top: 20vh;
    left: 120vw;
  }
}

@keyframes ar-diver-2 {
  0%{
    top: -10vh;
    left: -10vw;
  }
  50% {
    top: 22vh;
    left: 15vw;
  }
  100% {
    top: -20vh;
    left: 60vw;
  }
}

@keyframes ar-turtoise {
  0%{
    left: 120vw;
    top: 100vh;
  }
  100% {
    left: 10vw;
    top: 10vh;
  }
}

@keyframes ar-squid-1 {
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    left: 10vw;
    top: 40vh;
  }
  50% {
    opacity: 1;
    left: 8vw;
    top: 30vh;
  }
  60% {
    opacity: 1;
    left: 6vw;
    top: 30vh;
  }
  70% {
    opacity: 1;
    left: 7vw;
    top: 33vh;
  }
  80% {
    opacity: 1;
    left: 8vw;
    top: 35vh;
  }
  100%{
    opacity: 1;
    left: 10vw;
    top: 40vh;
  }
}

@keyframes ar-squid-2 {
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    right: 10vw;
    top: 3vh;
  }
  50% {
    opacity: 1;
    right: 11vw;
    top: 7vh;
  }
  60% {
    opacity: 1;
    right: 12vw;
    top: 9vh;
  }
  70% {
    opacity: 1;
    right: 13vw;
    top: 8vh;
  }
  80% {
    opacity: 1;
    right: 9vw;
    top: 5vh;
  }
  100%{
    opacity: 1;
    right: 10vw;
    top: 3vh;
  }
}

@keyframes ar-squid-3 {
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    bottom: 10vw;
    right: 20vw;
  }
  50% {
    opacity: 1;
    bottom: 15vw;
    right: 25vw;
  }
  60% {
    opacity: 1;
    bottom: 16vw;
    right: 26vw;
  }
  70% {
    opacity: 1;
    bottom: 15vw;
    right: 24vw;
  }
  80% {
    opacity: 1;
    bottom: 14vw;
    right: 25vw;
  }
  100%{
    opacity: 1;
    bottom: 15vw;
    right: 27vw;
  }
}

@keyframes ar-squid-4 {
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    left: 20vw;
    top: 30vh;
  }
  50% {
    opacity: 1;
    left: 23vw;
    top: 35vh;
  }
  60% {
    opacity: 1;
    left: 25vw;
    top: 38vh;
  }
  70% {
    opacity: 1;
    left: 24vw;
    top: 36vh;
  }
  80% {
    opacity: 1;
    left: 21vw;
    top: 32vh;
  }
  100%{
    opacity: 1;
    left: 20vw;
    top: 31vh;
  }
}

@keyframes ar-squid-5 {
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    left: 15vw;
    top: 23vh;
  }
  50% {
    opacity: 1;
    left: 13vw;
    top: 25vh;
  }
  60% {
    opacity: 1;
    left: 16vw;
    top: 22vh;
  }
  70% {
    opacity: 1;
    left: 14vw;
    top: 28vh;
  }
  80% {
    opacity: 1;
    left: 16vw;
    top: 25vh;
  }
  100%{
    opacity: 1;
    left: 15vw;
    top: 23vh;
  }
}

@keyframes ar-squid-6 {
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    left: 15vw;
    top: 55vh;
  }
  50% {
    opacity: 1;
    left: 16vw;
    top: 55vh;
  }
  60% {
    opacity: 1;
    left: 18vw;
    top: 55vh;
  }
  70% {
    opacity: 1;
    left: 19vw;
    top: 55vh;
  }
  80% {
    opacity: 1;
    left: 18vw;
    top: 55vh;
  }
  100%{
    opacity: 1;
    left: 16vw;
    top: 55vh;
  }
}

@keyframes ar-squid-7 {
  0%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    left: 40vw;
    top: 33vh;
  }
  50% {
    opacity: 1;
    left: 42vw;
    top: 31vh;
  }
  60% {
    opacity: 1;
    left: 45vw;
    top: 30vh;
  }
  70% {
    opacity: 1;
    left: 42vw;
    top: 31vh;
  }
  80% {
    opacity: 1;
    left: 44vw;
    top: 32vh;
  }
  100%{
    opacity: 1;
    left: 40vw;
    top: 33vh;
  }
}
</style>
