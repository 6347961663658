<template>
  <div v-if="getDetail" class="exploration-AR">
    <div class="left-block">
      <img class="model-img" :src="`https://homing.ntsec.gov.tw/files/${selectedInteraction.FileName}`" alt="Model">
    </div>
    <div class="right-block">
      <div class="interactions-block">
<!--        <div class="model-control-map">-->
<!--          <img src="../../../assets/images/icon-map.svg" @click="handleShowMap()" alt="地圖">-->
<!--        </div>-->
        <div class="interaction-title font-weight-medium">
          <span>{{ getModelName }}</span>
        </div>
        <div class="interaction-subTitle font-weight-thin">
          <span>{{ getDetail.SubTitle }}</span>
        </div>
        <div class="interaction-enter">
          <img src="../../../assets/images/icon-arrow-right.svg" alt="進入" @click="goARModel()">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'
import ExplorationArMixin from "@/mixins/exploration-ar-mixin";

export default {
  name: 'ExplorationAR',
  mixins: [ExplorationArMixin],
  methods: {

    goARModel() {
      let pageName = 'DesktopExplorationARBird'
      if (this.selectedInteraction.FileSize === 'BIRD') {
        pageName = 'DesktopExplorationARBird'
      } else if (this.selectedInteraction.FileSize === 'SNAKE') {
        pageName = 'DesktopExplorationARSNAKE'
      } else {
        pageName = 'DesktopExplorationARCUTTLEFISH'
      }
      router.push({
        name: pageName,
        query: {
          id: this.selectedInteraction.InteractionId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.exploration-AR {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #20272D;
  overflow: hidden;
  display: flex;
  .left-block {
    position: relative;
    width: 50%;
    height: 100%;
    color: #BCD7CE;
    display: flex;
    flex-direction: column;
    .model-img {
      position: relative;
      width: 100%;
      height: 77%;
      object-fit: cover;
      border-right: 6px solid #06DB94;
      border-bottom: 6px solid #06DB94;
      border-end-end-radius: 90px;
      //padding: 7vh 0;
      background: black;
      margin-left: -6px;
    }
  }
  .right-block {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .interactions-block {
      display: flex;
      flex-direction: column;
      color: white;
      padding: 60px;
      cursor: pointer;
      .model-control-map {
        height: 40px;
        text-align: left;
        padding-bottom: 20px;
        img {
          height: 100%;
          margin-right: 20px;
        }
      }
      .interaction-title {
        text-align: left;
        line-height: 45px;
        color: #BCD7CE;
        text-overflow: ellipsis;
        white-space: pre-line;
        span {
          font-size: 2.3rem;
        }
      }
      .interaction-subTitle {
        line-height: 35px;
        text-align: left;
        color: #BCD7CE;
        span {
          font-size: 1.8rem;
        }
      }
      .interaction-enter {
        width: 20%;
        display: flex; /* ★ */
        align-items: center; /* ★ */
        padding: 40px 0;
      }
    }
    .interactions-block.active {
      background-color: #5E927A;
    }
  }
}
</style>
