import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      currentTab: this.$store.getters.getArchiveTab,
      questionnaire: {},
      questionnairePages: ['Zero', 'First', 'Second', 'Third', 'Fourth', 'Last'],
      questionnaireAns: {},
      userCollectList: [],
      collectionList: [],
      ImportanceList: ['非常滿意', '滿意', '普通', '不滿意', '非常不滿意'],
      SatisfactionList: ['非常重要', '重要', '普通', '不重要', '非常不重要'],
      archiveRecordData: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
      },
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  mounted() {
    this.getQuestionnaireList()
    this.userCollectList = this.$store.state.userCollectionList
    this.$store.commit('LoadingOpen')
    this.$store.dispatch('GetCollectionList').then(res => {
      this.$store.commit('LoadingClose')
      if (res.success) {
        this.updateArchiveRecord()
        try {
          this.collectionList = res.collections
          this.collectionList = this.collectionList.filter(x => this.userCollectList.includes(x.CollectionId))
        } catch (e) {
          console.log(e)
        }
      } else {
        console.log(res)
      }
    })
  },
  filters: {
    changeFirstCharToUpper(str) {
      return str[0].toUpperCase() + str.slice(1);
    }
  },
  methods: {
    getTitleByLanguage(title) {
      console.log(`getTitleByLanguage(${title})`)
      switch (title) {
        case '非常':
          return this.currentLanguageKey === 'ZH_TW' ? '非常' : 'very'
        case '滿意':
          return this.currentLanguageKey === 'ZH_TW' ? '滿意' : 'satisfied'
        case '普通':
          return this.currentLanguageKey === 'ZH_TW' ? '普通' : 'neutral'
        case '不滿意':
          return this.currentLanguageKey === 'ZH_TW' ? '不滿意' : 'dissatisfied'
        case '重要':
          return this.currentLanguageKey === 'ZH_TW' ? '重要' : 'important'
        case '不重要':
          return this.currentLanguageKey === 'ZH_TW' ? '不重要' : 'unimportant'
        case '送出':
          return this.currentLanguageKey === 'ZH_TW' ? '送出' : 'send'
        case '歷程紀錄':
          return this.currentLanguageKey === 'ZH_TW' ? '歷程紀錄' : 'records'
        case '收藏檔案':
          return this.currentLanguageKey === 'ZH_TW' ? '收藏檔案' : 'favorites'
        case '問卷調查':
          return this.currentLanguageKey === 'ZH_TW' ? '問卷調查' : 'questionnaire'
        case '聆聽15個專家導覽':
          return this.currentLanguageKey === 'ZH_TW' ? '聆聽15個專家導覽' : 'Listened to 15 audio guides'
        case '聆聽30個專家導覽':
          return this.currentLanguageKey === 'ZH_TW' ? '聆聽30個專家導覽' : 'Listened to 30 audio guides'
        case '聆聽全部專家導覽':
          return this.currentLanguageKey === 'ZH_TW' ? '聆聽全部專家導覽' : 'Listened to all audio guides'
        case '體驗2個互動遊戲':
          return this.currentLanguageKey === 'ZH_TW' ? '體驗2個互動遊戲' : 'experienced 2 games'
        case '體驗全部互動遊戲':
          return this.currentLanguageKey === 'ZH_TW' ? '體驗全部互動遊戲' : 'experienced all games'
        case '完成問卷調查':
          return this.currentLanguageKey === 'ZH_TW' ? '完成問卷調查' : 'filled out the questionnaire'
        case '請填寫':
          return this.currentLanguageKey === 'ZH_TW' ? '請填寫' : 'please specify'
        case '確定要清除所有紀錄嗎？':
          return this.currentLanguageKey === 'ZH_TW' ? '確定要清除所有紀錄嗎？' : 'Are you sure to delete all items?'
        case '確定':
          return this.currentLanguageKey === 'ZH_TW' ? '確 定' : 'Yes'
        case '確認':
          return this.currentLanguageKey === 'ZH_TW' ? '確 認' : 'Confirm'
        case '取消':
          return this.currentLanguageKey === 'ZH_TW' ? '取 消' : 'Cancel'
        case '請至服務台確認是否兌換贈品':
          return this.currentLanguageKey === 'ZH_TW' ? '請至服務台確認是否兌換贈品' : 'Please go to the service counter and see if you can get a gift.'
        case '已兌換贈品':
          return this.currentLanguageKey === 'ZH_TW' ? '已兌換贈品' : 'Your gift is redeemed.'
        case '蒐集到「聆聽15個專家導覽」、「體驗2個互動遊戲」以及「完成問卷調查」這3枚徽章，即可憑此頁面至1樓服務台兌換找家無線電精美小禮物！':
          return this.currentLanguageKey === 'ZH_TW' ? '蒐集到「聆聽15個專家導覽」、「體驗2個互動遊戲」以及「完成問卷調查」3枚徽章，即可憑此頁面至1樓服務台兌換找家無線電精美小禮物！' : 'Collect 3 stamps, including listening to 15 audio guides, experiencing 2 games and filling out the questionnaire, you can redeem a gift from the service counter on 1F.'
        case '已集滿3枚徽章，是否確定兌換禮物(由工作人員點選)':
          return this.currentLanguageKey === 'ZH_TW' ? '已集滿3枚徽章，確定兌換禮物（由工作人員點選）' : 'Congrats! Are you sure to redeem a gift? Get help from the service counter.'
        case '您已兌換過禮物囉～歡迎繼續探索其他功能':
          return this.currentLanguageKey === 'ZH_TW' ? '您已兌換過禮物囉，歡迎繼續探索其他功能' : 'You’ve redeemed a gift. Welcome to explore other features.'
      }
    },
    getCollectCollectionTitle(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      console.log(detail)
      if (detail === null) {
        return ''
      } else if (detail.SubTitle && detail.SubTitle.length > 0) {
        return detail.SubTitle
      } else {
        return detail.Title
      }
    },
    getOtherDescHtml(content) {
      let res = content.ZH_TW.match(/https?:\/\/[^\s]+/)
      if (res) {
        return content.ZH_TW.replace(res[0], `<a href="${res[0]}" target="_blank" style="color: white">${res[0]}</a>`)
      } else {
        return ''
      }
    },
    updateArchiveRecord() {
      this.archiveRecordData = {
        1: this.$store.getters.listenAudioCount >= 15,
        2: this.$store.getters.listenAudioCount >= 30,
        3: this.$store.getters.collectionsCount > 0 && this.$store.getters.listenAudioCount >= this.$store.getters.collectionsCount,
        4: this.$store.getters.playInteractionCount >= 2,
        5: this.$store.getters.playInteractionCount >= 3,
        6: this.$store.getters.didFinishAdventure
      }
      // this.archiveRecordData = {
      //   1: true,
      //   2: true,
      //   3: true,
      //   4: true,
      //   5: true,
      //   6: true,
      // }
    },
    handleArchiveTabClick(tab) {
      this.currentTab = tab
      this.$store.dispatch('SetupArchiveTab', tab)
    },
    getQuestionnaireList() {
      if (process.env.NODE_ENV === 'development') {
        axios.get('/dev-file/questionnaire.json').then(res => {
          this.initQuestionnaireAns(res.data)
        }).catch(err => {
          console.log(err)
        });
      } else {
        axios.get('https://homing.ntsec.gov.tw/files/questionnaire.json').then(res => {
          this.initQuestionnaireAns(res.data)
        }).catch(err => {
          console.log(err)
        });
      }
    },
    initQuestionnaireAns(questionnaire) {
      let index = 0;
      const questionnaireAns = {}
      this.questionnairePages.forEach(x => {
        const q = questionnaire[x]
        q.QList.forEach(qIndex => {
          if (qIndex.Type !== 'Message') {
            if (qIndex.Type === 'Importance' || qIndex.Type === 'Satisfaction') {
              qIndex.Options.forEach(o => {
                index++
                const field = `Field${(index).toString().padStart(3, '0')}`
                questionnaireAns[field] = {
                  type: qIndex.Type,
                  title: `${qIndex.Title.ZH_TW}[${o.ZH_TW}]`,
                  ans: '',
                  other: ''
                }
                o.Field = field

              })
            } else {
              index++
              const field = `Field${(index).toString().padStart(3, '0')}`
              questionnaireAns[field] = {
                type: qIndex.Type,
                title: qIndex.Title.ZH_TW,
                ans: qIndex.Type === 'MultiOption' ? [] : '',
                other: ''
              }
              qIndex.Field = field
            }
          }
        })
      })
      this.questionnaireAns = questionnaireAns
      this.questionnaire = questionnaire
    },
    handleUnCollect(collection) {
      const index = this.userCollectList.indexOf(collection.CollectionId)
      this.userCollectList.splice(index, 1)
      this.$store.commit('SetUserCollected', this.userCollectList)
      this.collectionList = this.collectionList.filter(x => this.userCollectList.includes(x.CollectionId))
    },
    handleClearRecords() {
      console.log('handleClearRecords')
      this.$confirm(this.getTitleByLanguage('確定要清除所有紀錄嗎？'), {
        confirmButtonText: this.getTitleByLanguage('確定'),
        cancelButtonText: this.getTitleByLanguage('取消'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('ClearRecords')
        this.updateArchiveRecord()
      })
    },
    handleGiftRecords() {
      if (this.archiveRecordData[1] &&
        this.archiveRecordData[4] &&
        this.archiveRecordData[6])
      {
        if (this.$store.getters.getRedeemed) {
          this.$alert(this.getTitleByLanguage('蒐集到「聆聽15個專家導覽」、「體驗2個互動遊戲」以及「完成問卷調查」這3枚徽章，即可憑此頁面至1樓服務台兌換找家無線電精美小禮物！'),
            this.getTitleByLanguage('您已兌換過禮物囉～歡迎繼續探索其他功能'), {
            confirmButtonText: this.getTitleByLanguage('確認'),
            callback: () => {
            }
          });
        } else {
          this.$confirm(this.getTitleByLanguage('蒐集到「聆聽15個專家導覽」、「體驗2個互動遊戲」以及「完成問卷調查」這3枚徽章，即可憑此頁面至1樓服務台兌換找家無線電精美小禮物！'),
            this.getTitleByLanguage('已集滿3枚徽章，是否確定兌換禮物(由工作人員點選)'), {
            confirmButtonText: this.getTitleByLanguage('兌換'),
            cancelButtonText: this.getTitleByLanguage('取消'),
          }).then(() => {
            this.$store.commit('SetRedeemed')
            this.$alert(this.getTitleByLanguage('已兌換贈品'), {
              confirmButtonText: this.getTitleByLanguage('確認'),
              callback: () => {
              }
            });
          }).catch(() => {
          });
        }
      } else {
        this.$alert(this.getTitleByLanguage('蒐集到「聆聽15個專家導覽」、「體驗2個互動遊戲」以及「完成問卷調查」這3枚徽章，即可憑此頁面至1樓服務台兌換找家無線電精美小禮物！'), {
          confirmButtonText: this.getTitleByLanguage('確認'),
          callback: () => {
          }
        });
      }
    },
    goCollection(collectionIndex) {
      router.push({
        name: 'DesktopWayHome2',
        params: {
          id: this.collectionList[collectionIndex].CollectionId
        }
      })
    },
    handleSubmitArchive() {
      const _this = this
      const fieldKeys = Object.keys(_this.questionnaireAns)
      const title = {
        QuestionnaireVersion: 3,
        Seq: 0
      }
      const record = {
        QuestionnaireVersion: 3,
        Seq: 1
      }
      let haveEmptyAnswer = null

      for (let i = 0; i < fieldKeys.length > 0; i++) {
        const key = fieldKeys[i]
        console.log(key)
        title[key] = _this.questionnaireAns[key].title
        if (_this.questionnaireAns[key].type === 'MultiOption') {
          record[key] = _this.questionnaireAns[key].ans.join('，')
        } else {
          record[key] = _this.questionnaireAns[key].ans
        }
        if (_this.questionnaireAns[key].other.trim().length > 0) {
          if (record[key].trim().length > 0) {
            record[key] = record[key] + "，" + _this.questionnaireAns[key].other
          } else {
            record[key] = _this.questionnaireAns[key].other
          }
        }
        if (!record[key] || record[key].trim().length === 0) {
          haveEmptyAnswer = key
          break;
        }
      }
      if (haveEmptyAnswer) {
        alert('請確認所有問題均有回答完畢！')
        const element = document.getElementById(haveEmptyAnswer)
        if (element) {
          this.$nextTick(() => {
            element.scrollIntoView({behavior: "smooth", block: "end"})
          })
        }
        return
      }
      const recordList = [title, record]
      this.$store.commit('LoadingOpen')
      this.$store.dispatch('UploadQuestionnaire', recordList).then(res => {
        this.$store.commit('LoadingClose')
        if (res.success) {
          alert('提交成功')
          window.location.reload()
        } else {
          alert(res.message)
        }
      })
    }
  }
}
