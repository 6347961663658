import arMixin from "@/mixins/ar-mixin";

export default {
  mixins: [arMixin],
  mounted() {
  },
  methods: {
    handleOrientationChange() {
        if (window.orientation === 0 || window.orientation === 180) {
          // alert('請將裝置橫放掃描')
        } else {
            // this.$router.push({ name: 'Landscape' })
          alert('請將裝置直立掃描')
        }
    }
  }
}
