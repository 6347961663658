import router from "@/router";

export default {
  name: 'Exploration3DList',
  components: {
  },
  data() {
    return {
      interactionList: [],
      selectedInteraction: null,
      mapImageUrl: undefined,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  computed: {
    getDetail() {
      return this.selectedInteraction ? this.selectedInteraction.InteractionDetails.filter(d => d.LanguageKey === this.currentLanguageKey)[0] : null
    },
    getModelName() {
      const detail = this.getDetail
      if (detail) {
        return detail.ModelName
      } else {
        return ''
      }
    }
  },
  filters: {
  },
  mounted() {
    this.$store.commit('LoadingOpen')
    this.$store.dispatch('GetInteractionList').then(res => {
      this.$store.commit('LoadingClose')
      if (res.success) {
        try {
          console.log(res.interactions.filter(x => x.InteractionType === 1))
          this.interactionList = res.interactions.filter(x => x.InteractionType === 1 && x.FileSize !== 'CUTTLE')
          this.initSelectedInteraction()
        } catch (e) {
          alert(e.toString())
        }
      }
    })
  },
  methods: {
    initSelectedInteraction() {
      if (this.$route.query.id) {
        this.selectedInteraction = this.interactionList.filter(x => x.InteractionId === parseInt(this.$route.query.id))[0]
      } else {
        this.selectedInteraction = this.interactionList[0]
      }
    },
    getModelNameByInteraction(interaction) {
      const detail = interaction.InteractionDetails.filter(d => d.LanguageKey === this.currentLanguageKey)[0]
      if (detail) {
        return detail.ModelName
      } else {
        return ''
      }
    },
    changeIntercom(index) {
      this.intercomIndex = ((this.intercomIndex + index) + this.intercomList.length) % this.intercomList.length
    },
    goARModel(interaction) {
      router.push({
        name: 'DesktopExplorationAR',
        query: {
          id: interaction.InteractionId
        }
      })
    }
  }
}
