var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-main-menu"},[_c('div',{staticClass:"sub-menu",on:{"click":function($event){return _vm.goNext('MobileWayHome0')}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"sub-menu",on:{"click":function($event){return _vm.goNext('MobileExploration0')}}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"sub-menu",on:{"click":function($event){return _vm.goNext('MobileAdventureArchive')}}},[_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-menu-title-container"},[_c('div',{staticClass:"sub-menu-title sub-menu-title-zh"},[_vm._v("找家"),_c('br'),_vm._v("之路")]),_c('div',{staticClass:"sub-menu-title sub-menu-title-en"},[_vm._v("Ｗay Home"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter-btn"},[_c('img',{attrs:{"src":require("../../assets/images/icon-arrow-right.svg"),"alt":"進入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-menu-title-container"},[_c('div',{staticClass:"sub-menu-title sub-menu-title-zh"},[_vm._v("互動"),_c('br'),_vm._v("探索")]),_c('div',{staticClass:"sub-menu-title sub-menu-title-en"},[_vm._v("Exploration"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter-btn"},[_c('img',{attrs:{"src":require("../../assets/images/icon-arrow-right.svg"),"alt":"進入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-menu-title-container"},[_c('div',{staticClass:"sub-menu-title sub-menu-title-zh"},[_vm._v("探索"),_c('br'),_vm._v("檔案")]),_c('div',{staticClass:"sub-menu-title sub-menu-title-en"},[_vm._v("Advantutre"),_c('br'),_vm._v("Archive")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter-btn"},[_c('img',{attrs:{"src":require("../../assets/images/icon-arrow-right.svg"),"alt":"進入"}})])
}]

export { render, staticRenderFns }