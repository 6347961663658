<template>
  <div class="exploration-3D-list">
    <div v-if="selectedInteraction" class="left-block">
      <img class="model-img" :src="`https://homing.ntsec.gov.tw/files/${selectedInteraction.FileName}`" alt="Model">
      <div class="model-intro">
        <div class="model-body-title">
          <div class="model-name">
            <span>{{ getModelName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedInteraction" class="right-block">
      <div
          v-for="(interaction) in interactionList"
          :key="`interaction_${interaction.InteractionId}`"
          class="interactions-list-block"
          :class="{ 'active' : interaction.InteractionId === selectedInteraction.InteractionId }"
          @click="selectedInteraction = interaction"
      >
        <div class="interaction-title">
          <span>{{ getModelNameByInteraction(interaction) }}</span>
        </div>
        <div v-if="interaction.InteractionId === selectedInteraction.InteractionId" class="interaction-enter">
          <img src="../../../assets/images/icon-arrow-right.svg" alt="進入" @click="goARModel(interaction)">
        </div>
      </div>
    </div>
    <el-dialog
        title=""
        :visible.sync="mapImageUrl"
        fullscreen
        custom-class="map-dialog"
        center>
      <img :src="mapImageUrl" class="map-img" alt>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'
import ExplorationArMixin from "@/mixins/exploration-ar-mixin";

export default {
  name: 'Exploration3DList',
  mixins: [ExplorationArMixin],
  methods: {
    goARModel(interaction) {
      router.push({
        name: 'DesktopExplorationAR',
        query: {
          id: interaction.InteractionId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.exploration-3D-list {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #20272D;
  overflow: hidden;
  display: flex;
  .left-block {
    position: relative;
    width: 50%;
    height: 100%;
    color: #BCD7CE;
    display: flex;
    flex-direction: column;
    .model-img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-right: 6px solid #06DB94;
      border-bottom: 6px solid #06DB94;
      border-end-end-radius: 90px;
      //padding: 7vh 0;
      background: black;
      margin-left: -6px;
    }
    .model-intro {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 15vh;
      .model-body-title {
        .model-name {
          display: block;
          line-height: 30px;
          color: #BCD7CE;
          font-size: 1.3rem;
        }
        .model-size {
          display: block;
          line-height: 25px;
          color: #5B7673;
          font-size: 1.2rem;
        }
      }
      .model-body-control {
        position: absolute;
        right: 50px;
        top: 0;
        bottom: 0;
        width: 65px;
        height: 40px;
        margin: auto;
        .model-control-detail {
          float: left;
        }
        .model-control-map {
          float: right;
        }
      }
    }
  }
  .right-block {
    position: relative;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #1B2228;
    overflow-y: scroll;
    .interactions-list-block {
      display: flex;
      min-height: 70px;
      color: white;
      padding: 40px;
      cursor: pointer;
      .interaction-title {
        flex: 1;
        text-align: left;
        line-height: 45px;
        color: #CCDBCC;
        text-overflow: ellipsis;
        white-space: pre-line;
        span {
          //padding-left: 10px;
          font-size: 1.5rem;
        }
      }
      .interaction-size {
        width: 30%;
        line-height: 70px;
        text-align: center;
        span {
          background-color: #141B21;
          padding: 5px 10px;
          font-size: 1.5rem;
          color: #5E927A;
          border-radius: 20px;
        }
      }
      .interaction-enter {
        width: 20%;
        display: flex; /* ★ */
        align-items: center; /* ★ */
        img {
          margin: auto;
        }
      }
    }
    .interactions-list-block.active {
      background-color: #5E927A;
    }
  }
}
</style>
