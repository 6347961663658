<template>
  <div class="exploration-3D-list">
    <div v-if="selectedInteraction" class="left-block">
      <img class="model-img" :src="`https://homing.ntsec.gov.tw/files/${selectedInteraction.FileName}`" alt="Model">
      <div class="model-intro">
        <div class="model-body-title">
          <div class="model-name">
            <span>{{ getInteractionColumnByName(selectedInteraction, 'ModelName') }}</span>
          </div>
          <div class="model-size">
            <span v-if="selectedInteraction.FileSize">{{ selectedInteraction.FileSize }}</span>
          </div>
        </div>
        <div class="model-body-control">
<!--          <img class="model-control-detail" src="../../assets/images/icon-book.svg" alt="明細">-->
          <img class="model-control-map" src="../../../assets/images/icon-map.svg" @click="handleShowMap()" alt="地圖">
        </div>
      </div>
    </div>
    <div v-if="selectedInteraction" class="right-block">
      <div
          v-for="(interaction) in interactionList"
          :key="`interaction_${interaction.InteractionId}`"
          class="interactions-list-block"
          :class="{ 'active' : interaction.InteractionId === selectedInteraction.InteractionId }"
          @click="selectedInteraction = interaction"
      >
        <div class="interaction-title font-weight-regular">
          <span>{{ getInteractionColumnByName(interaction, 'ModelName') }}</span>
        </div>
        <div v-if="interaction.InteractionId === selectedInteraction.InteractionId" class="interaction-enter">
          <img src="../../../assets/images/icon-arrow-right.svg" alt="進入" @click="go3DModel(interaction)">
        </div>
<!--        <div v-else class="interaction-size">-->
<!--          <span v-if="interaction.FileSize">{{ interaction.FileSize }}</span>-->
<!--        </div>-->
      </div>
    </div>
    <el-dialog
        title=""
        :visible.sync="mapImageUrl"
        fullscreen
        custom-class="map-dialog"
        center>
      <img :src="mapImageUrl" class="map-img" alt>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'

export default {
  name: 'Exploration3DList',
  components: {
  },
  data() {
    return {
      interactionList: [],
      selectedInteraction: null,
      mapImageUrl: undefined,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  mounted() {
    this.$store.commit('LoadingOpen')
    this.$store.dispatch('GetInteractionList').then(res => {
      this.$store.commit('LoadingClose')
      if (res.success) {
        try {
          this.interactionList = res.interactions.filter(x => x.InteractionType === 0)
          this.selectedInteraction = this.interactionList[0]
        } catch (e) {
          alert(e.toString())
        }
      }
    })
  },
  methods: {
    getInteractionColumnByName(interaction, columnName) {
      const detail = interaction.InteractionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail[columnName]
    },
    changeIntercom(index) {
      this.intercomIndex = ((this.intercomIndex + index) + this.intercomList.length) % this.intercomList.length
    },
    handleCollect(interaction, isCollect) {
      // console.log(`handleCollect : ${JSON.stringify(interaction)} | ${isCollect}`)
      if (isCollect && this.userCollectList.includes(interaction.CollectionId) === false) {
        this.userCollectList.push(interaction.CollectionId)
        interaction.IsCollect = true
      } else if (isCollect === false && this.userCollectList.includes(interaction.CollectionId)) {
        const index = this.userCollectList.indexOf(interaction.CollectionId)
        this.userCollectList.splice(index, 1)
        interaction.IsCollect = false
      }
      // console.log(this.userCollectList)
      localStorage.setItem('collects', JSON.stringify(this.userCollectList))
    },
    handleShowMap() {
      if (this.selectedInteraction.MapFileName && this.selectedInteraction.MapFileName.trim().length > 0) {
        this.mapImageUrl = `https://homing.ntsec.gov.tw/files/${decodeURIComponent(this.selectedInteraction.MapFileName)}`
      } else {
        alert('尚未上傳檔案')
      }
    },
    go3DModel(interaction) {
      console.log(interaction)
      router.push({
        name: 'DesktopExploration3D',
        query: {
          id: interaction.InteractionId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.exploration-3D-list {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #20272D;
  overflow: hidden;
  display: flex;
  .left-block {
    position: relative;
    width: 50%;
    height: 100%;
    color: #BCD7CE;
    display: flex;
    flex-direction: column;
    .model-img {
      position: relative;
      width: 100%;
      height: 85vh;
      object-fit: contain;
      border-right: 6px solid #06DB94;
      border-bottom: 6px solid #06DB94;
      border-end-end-radius: 90px;
      //padding: 7vh 0;
      background: black;
      margin-left: -6px;
    }
    .model-intro {
      display: flex;
      height: 15vh;
      justify-content: center;
      align-items: center;
      position: relative;
      .model-body-title {
        .model-name {
          display: block;
          line-height: 30px;
          color: #BCD7CE;
          font-size: 1.3rem;
        }
        .model-size {
          display: block;
          line-height: 25px;
          color: #5B7673;
          font-size: 1.2rem;
        }
      }
      .model-body-control {
        position: absolute;
        right: 50px;
        top: 0;
        bottom: 0;
        width: 65px;
        height: 40px;
        margin: auto;
        .model-control-detail {
          float: left;
        }
        .model-control-map {
          float: right;
          display: none;
        }
      }
    }
  }
  .right-block {
    position: relative;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #1B2228;
    overflow-y: scroll;
    .interactions-list-block {
      display: flex;
      height: 70px;
      color: white;
      padding: 40px;
      cursor: pointer;
      .interaction-title {
        width: 60%;
        text-align: left;
        line-height: 70px;
        color: #CCDBCC;
        span {
          padding-left: 10px;
          font-size: 1.5rem;
        }
      }
      .interaction-size {
        width: 40%;
        line-height: 70px;
        text-align: center;
        span {
          background-color: #141B21;
          padding: 5px 10px;
          font-size: 1.5rem;
          color: #5E927A;
          border-radius: 20px;
        }
      }
      .interaction-enter {
        width: 40%;
        display: flex; /* ★ */
        align-items: center; /* ★ */
        img {
          margin: auto;
        }
      }
    }
    .interactions-list-block.active {
      background-color: #5E927A;
    }
  }
}
</style>
