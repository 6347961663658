<template>
  <div>
    <video id="video" class="camera"></video>
    <div v-if="!isDetected" class="compare-container">
      <img id="CameraImage" :src="cameraImage" style="visibility: hidden" alt>
      <img id="CompareImage" src="../../../../assets/images/ar-cuttle-compare.jpg" style="visibility: hidden" alt>
      <canvas id="CameraCanvas"></canvas>
<!--      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; width: 300px; height: 50px; color: red; font-size: 30px">-->
<!--        相似度: {{ similarity }}-->
<!--      </div>-->
    </div>
    <div v-else class="ar-container">
<!--      <img class="dev-bg" src="../../../../assets/images/ar-bird-bg.png" alt>-->
      <!-- 鳥頭區塊 -->
      <div>
        <div class="bird-head-A" :class="{ 'show': activeBird === 'birdA' }">
          <img v-if="activeFood === undefined" src="../../../../assets/images/ar-bird/icon-bird-head-A.png" alt>
          <img v-else-if="activeFood === 'insect' || activeFood === 'fruit'" src="../../../../assets/images/ar-bird/icon-bird-head-A-happy.gif" alt>
          <img v-else-if="activeFood !== 'insect' && activeFood !== 'fruit'" src="../../../../assets/images/ar-bird/icon-bird-head-A-unhappy.gif" alt>
        </div>
        <div class="bird-head-B" :class="{ 'show': activeBird === 'birdB' }">
          <img v-if="activeFood === undefined" src="../../../../assets/images/ar-bird/icon-bird-head-B.png" alt>
          <img v-else-if="activeFood === 'insect'" src="../../../../assets/images/ar-bird/icon-bird-head-B-happy.gif" alt>
          <img v-else-if="activeFood !== 'insect'" src="../../../../assets/images/ar-bird/icon-bird-head-B-unhappy.gif" alt>
        </div>
        <div class="bird-head-E" :class="{ 'show': activeBird === 'birdE' }">
          <img v-if="activeFood === undefined" src="../../../../assets/images/ar-bird/icon-bird-head-E.png" alt>
          <img v-else-if="activeFood === 'insect'" src="../../../../assets/images/ar-bird/icon-bird-head-E-happy.gif" alt>
          <img v-else-if="activeFood !== 'insect'" src="../../../../assets/images/ar-bird/icon-bird-head-E-unhappy.gif" alt>
        </div>
        <div class="bird-head-F" :class="{ 'show': activeBird === 'birdF' }">
          <img v-if="activeFood === undefined" src="../../../../assets/images/ar-bird/icon-bird-head-F.png" alt>
          <img v-else-if="activeFood === 'insect'" src="../../../../assets/images/ar-bird/icon-bird-head-F-happy.gif" alt>
          <img v-else-if="activeFood !== 'insect'" src="../../../../assets/images/ar-bird/icon-bird-head-F-unhappy.gif" alt>
        </div>
      </div>
      <div v-if="showBirdContent" class="ar-bird-food-container">
        <img src="../../../../assets/images/ar-bird/icon-bird-food-fruit.svg" :class="{ 'opacity100': activeFood === 'fruit' }" @click="handleCheckFood('fruit')" alt>
        <img src="../../../../assets/images/ar-bird/icon-bird-food-insect.svg" :class="{ 'opacity100': activeFood === 'insect' }" @click="handleCheckFood('insect')" alt>
        <img src="../../../../assets/images/ar-bird/icon-bird-food-fish.svg" :class="{ 'opacity100': activeFood === 'fish' }" @click="handleCheckFood('fish')" alt>
        <img src="../../../../assets/images/ar-bird/icon-bird-food-flower.svg" :class="{ 'opacity100': activeFood === 'flower' }" @click="handleCheckFood('flower')" alt>
        <img src="../../../../assets/images/ar-bird/icon-bird-food-rebbit.svg" :class="{ 'opacity100': activeFood === 'rebbit' }" @click="handleCheckFood('rebbit')" alt>
      </div>
      <!-- 中間說明區塊 -->
      <div class="ar-bird-body" :style="{ bottom: showBirdContent ? '25vh' : '15vh' }">
        <div class="box-info">
          <div class="box-info-body">
            <span>{{ getInfoTitle }}</span>
            <span></span>
            <span>{{ getInfoDesc }}</span>
          </div>
          <div v-if="!showBirdContent" class="box-info2-body">
            <img v-if="activeBird !== 'birdB'" src="../../../../assets/images/ar-bird/icon-bird-1.svg" @click="handleChangeActiveBird('birdB')" alt="">
            <img v-if="activeBird === 'birdB'" src="../../../../assets/images/ar-bird/icon-bird-1-fill.svg" @click="handleChangeActiveBird('birdB')" alt="">
            <img v-if="activeBird !== 'birdA'" src="../../../../assets/images/ar-bird/icon-bird-2.svg" @click="handleChangeActiveBird('birdA')" alt="">
            <img v-if="activeBird === 'birdA'" src="../../../../assets/images/ar-bird/icon-bird-2-fill.svg" @click="handleChangeActiveBird('birdA')" alt="">
            <img v-if="activeBird !== 'birdF'" src="../../../../assets/images/ar-bird/icon-bird-3.svg" @click="handleChangeActiveBird('birdF')" alt="">
            <img v-if="activeBird === 'birdF'" src="../../../../assets/images/ar-bird/icon-bird-3-fill.svg" @click="handleChangeActiveBird('birdF')" alt="">
            <img v-if="activeBird !== 'birdE'" src="../../../../assets/images/ar-bird/icon-bird-4.svg" @click="handleChangeActiveBird('birdE')" alt="">
            <img v-if="activeBird === 'birdE'" src="../../../../assets/images/ar-bird/icon-bird-4-fill.svg" @click="handleChangeActiveBird('birdE')" alt="">
          </div>
          <img v-if="activeBird && !showBirdContent" src="../../../../assets/images/icon-arrow-right.svg" class="enter-button" alt="" @click="showBirdContent = true; activeFood = undefined">
          <img v-if="showBirdContent" src="../../../../assets/images/icon-arrow-back.svg" class="enter-button" alt="" @click="showBirdContent = false; activeFood = undefined">
        </div>
        <div class="box-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ArBirdMixin from "@/mixins/ar-bird-mixin";

export default {
  name: "ArBird",
  mixins: [ArBirdMixin],
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.camera {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  object-fit: cover;
}
.compare-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.ar-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .dev-bg {
    width: 100%;
    height: 100%;
    margin-left: 60px;
    object-fit: cover;
  }
  .bird-head-A {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bird-head-B {
    position: absolute;
    width: 180px;
    height: 180px;
    right: 50vw;
    bottom: 53vh;
    display: none;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bird-head-E {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 70vw;
    top: 70vh;
    display: none;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bird-head-F {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 72vw;
    top: 48vh;
    display: none;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .show {
    display: block;
  }
  .ar-bird-body {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15vh;
    width: 50%;
    margin: auto;
    .box-1 {
      height: 35%;
    }
    .box-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      .box-info-body {
        display: flex;
        flex-direction: column;
        width: 258px;
        min-height: 127px;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 20px;
        padding: 20px;
        margin: auto;
        span:nth-child(1) {
          font-size: 1.8rem;
          color: #BCD7CE;
        }
        span:nth-child(2) {
          border: 2px solid #07DB95;
          margin: 5px 0;
        }
        span:nth-child(3) {
          font-size: 1.3rem;
          color: #FFFFFF;
        }
      }
      .box-info2-body {
        width: 350px;
        height: 65px;
        border: 2px #07DB95 solid;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 32.5px;
        padding: 0 20px;
        margin: 40px auto 20px auto;
        img {
          height: 55px;
          margin: 5px;
        }
      }
      .enter-button {
        width: 55px;
        margin: auto;
        padding: 10px;
      }
    }
    .box-3 {
      height: 35%;
    }
  }
}
.ar-bird-food-container {
  position: absolute;
  width: 300px;
  height: 150px;
  right: 60vw;
  bottom: 65vh;
  img {
    width: 40%;
    height: 30%;
    margin: 5%;
    opacity: 0.25;
  }
  img:nth-child(1) {
    margin-left: 40%;
  }
}

.opacity100 {
  opacity: 1 !important;
}
</style>
